import { Checkbox } from '@consigli/facade';
import { useState, ChangeEvent } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const NeedClassification = () => {
  const { control, getValues } = useFormContext<FieldValues>();
  const { t } = useTranslation();
  const [needClassificationValue, setNeedClassificationValue] = useState<boolean>(
    getValues('needClassification'),
  );

  return (
    <span className="mt-4">
      <Controller
        name="needClassification"
        control={control}
        render={({ field }) => (
          <div className="flex">
            <Checkbox
              id="needClassification"
              label={t('package-creation.need-classification')}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.checked);
                setNeedClassificationValue(event.target.checked);
              }}
              value={needClassificationValue?.toString()}
              checked={needClassificationValue}
            ></Checkbox>
          </div>
        )}
      />
    </span>
  );
};
