import { Checkbox, Position, Search, CustomSelect, SelectOption } from '@consigli/facade';
import { FindingCommenter } from '@consigli/types';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFindingsContext } from './findings-context';
import { FindingBlob } from './findings-wrapper';

interface FindingsFilterProps {
  selectAllChecked: boolean;
  handleSelectAllCheckboxClick: () => void;
  uniqueBlobs: FindingBlob[];
  commenters: FindingCommenter[];
}

export const FindingsFilter: FC<FindingsFilterProps> = ({
  selectAllChecked,
  handleSelectAllCheckboxClick,
  uniqueBlobs,
  commenters,
}) => {
  const { t } = useTranslation();
  const {
    selectedBlobIds,
    setSelectedBlobIds,
    selectedCommenterIds,
    setSelectedCommenterIds,
    searchTerm,
    setSearchTerm,
    setPage,
    showFindingIds,
    setShowFindingIds,
  } = useFindingsContext();

  const filenameOptions = useMemo(
    () =>
      uniqueBlobs?.map((blob: FindingBlob) => {
        if (blob.id === '__projectrelated__') {
          return { label: t('findings.project-related-finding'), value: blob.id };
        }
        return { label: blob.name, value: blob.id };
      }),
    [t, uniqueBlobs],
  );

  const commenterOptions = useMemo(
    () =>
      commenters.map((commenter: FindingCommenter) => {
        return { label: commenter.name, value: commenter.id };
      }),
    [commenters],
  );

  const handleFilterBlobsChange = useCallback(
    (options: SelectOption[]) => {
      if (options.length > 0) {
        setSelectedBlobIds(options.map((option) => option.value.toString()));
      } else {
        setSelectedBlobIds([]);
      }
      setPage(1);
    },
    [setPage, setSelectedBlobIds],
  );

  const handleFilterCommentersChange = useCallback(
    (options: SelectOption[]) => {
      if (options.length > 0) {
        setSelectedCommenterIds(options.map((option) => option.value.toString()));
      } else {
        setSelectedCommenterIds([]);
      }
      setPage(1);
    },
    [setPage, setSelectedCommenterIds],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(1);
    },
    [setPage, setSearchTerm],
  );

  return (
    <div className="flex flex-col sm:flex-row items-center justify-start">
      <div className="pl-1">
        <Checkbox
          checked={selectAllChecked}
          label={t('findings.select-all')}
          value="Select all"
          id="findingSelectAll"
          labelPosition={Position.RIGHT}
          onChange={() => handleSelectAllCheckboxClick()}
        />
      </div>
      <div className="flex-1 z-[11] sm:pr-4 pb-2 sm:pb-0 w-full">
        <CustomSelect
          onChange={(selectedOptions: SelectOption[]): void => {
            handleFilterBlobsChange(selectedOptions);
          }}
          isMulti
          isClearable
          placeholder={t('findings.filter-on-documents')}
          options={filenameOptions}
          value={
            selectedBlobIds
              ? filenameOptions.filter((option) => selectedBlobIds.includes(option.value))
              : []
          }
          className="w-full"
          noOptionsMessage={t('custom-select.no-options')}
        />
      </div>
      <div className="flex-1 z-[11] sm:pr-4 pb-2 sm:pb-0 w-full">
        <CustomSelect
          onChange={(selectedOptions: SelectOption[]): void => {
            handleFilterCommentersChange(selectedOptions);
          }}
          isMulti
          isClearable
          placeholder={t('findings.filter-on-commenters')}
          options={commenterOptions}
          value={
            selectedCommenterIds
              ? commenterOptions.filter((option) => selectedCommenterIds.includes(option.value))
              : []
          }
          className="w-full"
          noOptionsMessage={t('custom-select.no-options')}
        />
      </div>
      <div className="flex-1 w-full">
        <Search
          text={searchTerm}
          onChange={handleSearch}
          placeholder={t('findings.search-findings')}
          className="w-full"
        />
      </div>
      <Checkbox
        checked={showFindingIds}
        className="ml-4"
        label={t('findings.show-finding-ids')}
        id="findingShowIds"
        labelPosition={Position.RIGHT}
        onChange={() => setShowFindingIds((i) => !i)}
      />
    </div>
  );
};
