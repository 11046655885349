import { Button } from '@consigli/facade';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useTranslation } from 'react-i18next';
import { FaBan } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';

import { MultipleSelectionDialog } from '@/components/multiple-selection-dialog';
import { snapCenterToCursor } from '@/util/dragging-util';

interface ConfirmProjectRemovalPopupProps {
  showButtons: boolean;
  setShowButtons: React.Dispatch<React.SetStateAction<boolean>>;
  removeProject: () => void;
}

export const ConfirmProjectRemovalPopup = (props: ConfirmProjectRemovalPopupProps) => {
  const { showButtons, setShowButtons, removeProject } = props;
  const { t } = useTranslation();
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor),
    useSensor(PointerSensor),
  );

  if (!showButtons) {
    return <></>;
  }
  return (
    <DndContext sensors={sensors} modifiers={[snapCenterToCursor, restrictToWindowEdges]}>
      <MultipleSelectionDialog className="w-[320px]" dragId="confirm-project-removal">
        <div className="flex items-center justify-between pb-4">
          <span className="font-bold pr-4">{t('invite-user.remove-user')}</span>
        </div>
        <span className="absolute right-0 top-0 cursor-pointer p-4">
          <RxCross2 fontSize={24} onClick={() => setShowButtons(false)} />
        </span>
        <div className="flex w-full justify-between items-center gap-2">
          <Button className="grow" rounded secondary onClick={() => setShowButtons(false)}>
            {t('invite-user.cancel')}
          </Button>
          <Button
            className="grow"
            icon={FaBan}
            iconColor="red"
            rounded
            secondary
            onClick={removeProject}
          >
            {t('invite-user.remove-access')}
          </Button>
        </div>
      </MultipleSelectionDialog>
    </DndContext>
  );
};
