import { CustomSelect, SelectOption } from '@consigli/facade';
import { useMemo, type FC, useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProjectInputs } from '@/util/types';

interface ProjectTypeProps {
  control: Control<ProjectInputs>;
}

export const ProjectType: FC<ProjectTypeProps> = ({ control }) => {
  const { t } = useTranslation();

  const projectTypeChoices: SelectOption[] = useMemo(
    () => [
      {
        value: 1,
        label: t('project-type.design-and-build'),
      },
      {
        value: 2,
        label: t('project-type.partnering-alliancing'),
      },
      {
        value: 3,
        label: t('project-type.traditional-procurement'),
      },
      {
        value: 4,
        label: t('project-type.main-contractor'),
      },
      {
        value: 5,
        label: t('project-type.subcontracting-firms'),
      },
      {
        value: 6,
        label: t('project-type.construction-management'),
      },
      {
        value: 8,
        label: t('project-type.other'),
      },
    ],
    [t],
  );

  const { field, fieldState } = useController({
    name: 'projectType',
    control,
    rules: { required: `${t('project-creation.project-type-required')}*` },
  });

  const onProjectTypeChange = useCallback(
    (selectedOptions: SelectOption[]): void => {
      field.onChange(selectedOptions.length > 0 ? selectedOptions[0].value : null);
    },
    [field],
  );

  return (
    <div>
      <CustomSelect
        options={projectTypeChoices}
        onChange={onProjectTypeChange}
        value={
          field.value
            ? projectTypeChoices.filter(
                (projectType: SelectOption) => projectType.value === field.value,
              )
            : []
        }
        placeholder={t('project-creation.project-type-placeholder')}
        label={t('project-creation.project-type-label')}
        noOptionsMessage={t('custom-select.no-options')}
        className="w-[400px]"
        isClearable
      />
      {fieldState.invalid && (
        <div className={'text-sm my-1 text-error-dark'}>{fieldState.error?.message}</div>
      )}
    </div>
  );
};
