import { Button, TextArea } from '@consigli/facade';
import {
  useGetFindingCommentsQuery,
  useCreateFindingCommentMutation,
  useKeyListener,
} from '@consigli/hooks';
import { FindingComment, FindingCommentType, FindingDocument } from '@consigli/types';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsDot } from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';
import { SlPaperPlane } from 'react-icons/sl';

import robot from '@/assets/images/robot.webp';

interface FindingsCommentSectionProps {
  finding: FindingDocument;
}

export const FindingsCommentSection = ({ finding }: FindingsCommentSectionProps) => {
  const { data: comments = [] } = useGetFindingCommentsQuery({
    findingId: finding.findingId,
    projectId: finding.projectId,
    packageId: finding.documentPackageId,
  });

  const [createFindingComment] = useCreateFindingCommentMutation();
  const [newComment, setNewComment] = useState('');
  const { t } = useTranslation();

  const getIcon = useCallback((comment: FindingComment) => {
    const icon =
      comment.type !== FindingCommentType.USER_COMMENT ? (
        <img src={robot} alt="consigli silhouette" className="h-8 w-8" />
      ) : (
        <FaUser size={32} className="text-day-neutral-dark" />
      );
    return icon;
  }, []);

  const sendComment = () => {
    if (!newComment) return;
    createFindingComment({
      projectId: finding.projectId,
      packageId: finding.documentPackageId,
      findingId: finding.findingId,
      text: newComment,
      type: FindingCommentType.USER_COMMENT,
    });
    setNewComment('');
  };

  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = date.getDate();
    // getMonth is 0-indexed, so have to + 1
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).substring(2);

    return `${hours}:${minutes}, ${day}/${month}/${year}`;
  }, []);

  useKeyListener('Enter', sendComment);

  return (
    <div className="flex flex-col flex-start h-[calc(100vh-7.5rem)] text-brand-default">
      <div className="overflow-y-auto">
        {comments.map((comment) => (
          <div key={comment.id} className="flex">
            <div className="flex flex-row my-2 mr-10">
              <div className="m-2 shrink-0">{getIcon(comment)}</div>
              <div>
                <div className="flex flex-row items-center">
                  <b>
                    {comment.type !== FindingCommentType.USER_COMMENT
                      ? 'Consigli'
                      : comment.authorName}
                  </b>
                  <BsDot className="mx-2 text-night-light-5" />
                  {comment.createdAt && (
                    <div className="text-night-light-5">{formatDate(comment.createdAt)}</div>
                  )}
                </div>
                <div>
                  {comment.type !== FindingCommentType.USER_COMMENT
                    ? t(`findings.comment-codes.${comment.type.toLowerCase()}`, {
                        commentText: comment.text,
                      })
                    : comment.text}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row mx-2 mt-auto">
        <div className="flex-1">
          <TextArea
            className="w-full"
            value={newComment}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              setNewComment(event.target.value)
            }
            placeholder={t('findings.add_comment')}
          />
        </div>
        <Button
          className="p-0 mb-2 mx-2"
          tertiary
          onClick={sendComment}
          onKeyDown={sendComment}
          icon={SlPaperPlane}
          iconSize={35}
        />
      </div>
    </div>
  );
};
