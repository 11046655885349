import { renderLoader } from '@consigli/utils';
import { DocumentLoadEvent, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import {
  HighlightArea,
  RenderHighlightsProps,
  Trigger,
  highlightPlugin,
} from '@react-pdf-viewer/highlight';
import { OnHighlightKeyword, searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react';

import { usePDFToolbar } from './custom-toolbar';
import { PDFViewerError } from './pdf-viewer-error';
import { regexpIgnoreWhitespaces } from './search-regexp';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

// Types
type FindingCoordinates = {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  pageIndex: number;
};

type PDFSize = {
  pdfWidth: number;
  pdfHeight: number;
  pageCount: number;
};

type InitializedState = {
  findingScrolled: boolean;
  searchScrolled: boolean;
  documentLoaded: boolean;
  initialLoadHandled: boolean;
  keywordScrolled: boolean;
};

type PDFViewerProps = {
  /**
   * Initial page number to jump to upon opening the PDF (0-indexed)
   */
  initialPageNumber?: number;

  /**
   * Coordinates for the finding to be highlighted
   */
  findingCoordinates?: FindingCoordinates;

  /**
   * Search term to highlight
   */
  searchTerm?: string;

  /**
   * Fallback page number if search fails to match (0-indexed)
   */
  searchFallbackPage?: number;

  /**
   * Path to the PDF document to render
   */
  fileUrl: string;

  /**
   * Whether to show in preview mode (with limited toolbar)
   */
  isPreview: boolean;

  /**
   * Name of the document being displayed
   */
  documentName: string;

  /**
   * Unique ID for the PDF viewer instance
   */
  id?: number;
};

// Constants
const PADDING = 25;
const HIGHLIGHT_COLOR = 'yellow';
const HIGHLIGHT_OPACITY = 0.4;
const HIGHLIGHT_RENDER_DELAY = 100;

export const PDFViewer: FC<PDFViewerProps> = ({
  initialPageNumber = 0,
  findingCoordinates,
  searchTerm,
  searchFallbackPage = 0,
  fileUrl,
  isPreview = true,
  documentName,
  id = 1,
}) => {
  // State
  const [pdfSize, setPdfSize] = useState<PDFSize | null>(null);

  // Track initialization state to prevent duplicate operations
  const initialized = useRef<InitializedState>({
    findingScrolled: false,
    searchScrolled: false,
    documentLoaded: false,
    initialLoadHandled: false,
    keywordScrolled: false,
  });

  // Process search term into a regex pattern
  const keyword = useMemo(() => {
    if (!searchTerm || searchTerm.trim() === '') return undefined;
    return regexpIgnoreWhitespaces(searchTerm.replace('|', ''));
  }, [searchTerm]);

  // Calculate highlight area based on finding coordinates and PDF size
  const highlightArea: HighlightArea | undefined = useMemo(() => {
    if (!findingCoordinates || !pdfSize || pdfSize.pdfHeight === 0 || pdfSize.pdfWidth === 0) {
      return undefined;
    }

    const { x0, x1, y0, y1, pageIndex } = findingCoordinates;
    const { pdfHeight, pdfWidth } = pdfSize;

    // Convert coordinates to percentages for highlighting
    const height = (Math.abs(y0 - y1) * 100) / pdfHeight;
    const width = (Math.abs(x0 - x1) * 100) / pdfWidth;
    const top = (Math.min(y0, y1) * 100) / pdfHeight;
    const left = (Math.min(x0, x1) * 100) / pdfWidth;

    return { height, left, top, width, pageIndex };
  }, [findingCoordinates, pdfSize]);

  // Get toolbar components and plugins
  const { fullscreenToolbar, previewToolbar, navigation, zoom, download, search } =
    usePDFToolbar(documentName);

  // Configure highlight plugin
  const renderHighlights = useCallback(
    (props: RenderHighlightsProps) => {
      if (highlightArea === undefined || highlightArea.pageIndex !== props.pageIndex) {
        return <></>;
      }

      return (
        <div
          style={{
            background: HIGHLIGHT_COLOR,
            opacity: HIGHLIGHT_OPACITY,
            ...props.getCssProperties(highlightArea, props.rotation),
          }}
        />
      );
    },
    [highlightArea],
  );

  const highlight = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  // Configure toolbar plugin
  const toolbar = toolbarPlugin({
    searchPlugin: {
      enableShortcuts: highlightArea == null,
      keyword,
    },
  });

  // Configure search plugin
  const searchPluginInstance = searchPlugin({
    onHighlightKeyword: (props: OnHighlightKeyword) => {
      if (!highlightArea) {
        props.highlightEle.style.backgroundColor = HIGHLIGHT_COLOR;
        props.highlightEle.style.opacity = String(HIGHLIGHT_OPACITY);
      }
    },
  });

  /**
   * Scrolls the PDF view to the highlighted area
   */
  const scrollToHighlightArea = useCallback(
    (
      pageContainer: Element,
      page: Element,
      coordinates: { pageIndex: number },
      area: HighlightArea,
    ) => {
      const scrollHeight = page.getBoundingClientRect().height;
      const offsetPageIndex = coordinates.pageIndex * scrollHeight;
      const offsetHighlightOnPage = (area.top / 100) * scrollHeight;

      const scrollTop = offsetPageIndex + offsetHighlightOnPage - PADDING;

      // Ensure scrollTop is within bounds
      const maxScrollTop = pageContainer.scrollHeight - pageContainer.clientHeight;
      const finalScrollTop = Math.min(Math.max(scrollTop, 0), maxScrollTop);

      requestAnimationFrame(() =>
        pageContainer.scrollTo({
          top: finalScrollTop,
          behavior: 'smooth',
        }),
      );
    },
    [],
  );

  /**
   * Clears existing highlights and sets target pages for search
   */
  const clearHighlightsAndSetTargetPages = useCallback(() => {
    toolbar.searchPluginInstance.clearHighlights();

    if (keyword) {
      return;
    } else if (searchFallbackPage !== 0) {
      toolbar.searchPluginInstance.setTargetPages(
        (targetPage) => targetPage.pageIndex >= searchFallbackPage - 2,
      );
    } else {
      toolbar.searchPluginInstance.setTargetPages(() => true);
    }
  }, [keyword, searchFallbackPage, toolbar.searchPluginInstance]);

  /**
   * Handles keyword search and navigation to search results
   */
  const handleKeywordSearch = useCallback(async () => {
    if (!keyword) {
      return;
    }

    initialized.current.keywordScrolled = true;
    clearHighlightsAndSetTargetPages();

    try {
      const matches = await toolbar.searchPluginInstance.highlight(keyword);

      if (matches.length === 0) {
        navigation.jumpToPage(searchFallbackPage);
      } else {
        // Wait for highlights to render before jumping
        setTimeout(() => {
          toolbar.searchPluginInstance.jumpToMatch(0);
        }, HIGHLIGHT_RENDER_DELAY);
      }
    } catch (error) {
      console.error('Error highlighting keyword:', error);
      navigation.jumpToPage(searchFallbackPage);
    }
  }, [
    keyword,
    clearHighlightsAndSetTargetPages,
    toolbar.searchPluginInstance,
    navigation,
    searchFallbackPage,
  ]);

  /**
   * Handles page change events and scrolls to the appropriate location
   */
  const handlePageChange = useCallback(async () => {
    // Skip if already scrolled to finding or search result
    if (initialized.current.findingScrolled || initialized.current.searchScrolled) {
      return;
    }

    // Handle finding coordinates highlight
    if (findingCoordinates && highlightArea) {
      initialized.current.findingScrolled = true;
      clearHighlightsAndSetTargetPages();

      const pageContainer = document.querySelector(`[data-key="${id}"] .rpv-core__inner-pages`);
      const page = document.querySelector(`[data-key="${id}"] .rpv-core__inner-page`);

      if (pageContainer && page) {
        pageContainer.classList.add('scroll-smooth');
        scrollToHighlightArea(pageContainer, page, findingCoordinates, highlightArea);
        initialized.current.searchScrolled = true;
      } else {
        requestAnimationFrame(() => navigation.jumpToPage(findingCoordinates.pageIndex));
        initialized.current.searchScrolled = true;
      }
      return;
    }

    // Handle keyword search
    if (keyword) {
      initialized.current.searchScrolled = true;
      handleKeywordSearch();
    }
  }, [
    findingCoordinates,
    highlightArea,
    keyword,
    clearHighlightsAndSetTargetPages,
    id,
    navigation,
    handleKeywordSearch,
    scrollToHighlightArea,
  ]);

  /**
   * Handles successful document load
   */
  const onDocumentLoadSuccess = useCallback(
    (loadEvent: DocumentLoadEvent) => {
      if (!initialized.current.documentLoaded) {
        loadEvent.doc.getPage(1).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          setPdfSize({
            pdfWidth: viewport.width,
            pdfHeight: viewport.height,
            pageCount: loadEvent.doc.numPages,
          });

          handlePageChange();
          initialized.current.documentLoaded = true;
        });
      }
    },
    [handlePageChange],
  );

  // Set up window resize handler to adjust zoom
  useEffect(() => {
    if (!initialized.current.initialLoadHandled) {
      initialized.current.initialLoadHandled = true;

      const handleResize = () => {
        zoom.zoomTo(SpecialZoomLevel.PageWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [zoom]);

  // Handle keyword search when keyword changes
  useEffect(() => {
    if (keyword && !initialized.current.keywordScrolled) {
      initialized.current.searchScrolled = false;
      initialized.current.keywordScrolled = false;
      handleKeywordSearch();
    }
  }, [keyword, handleKeywordSearch]);

  // Cleanup on unmount
  useEffect(() => {
    const initializedRef = initialized.current;
    return () => {
      initializedRef.documentLoaded = false;
    };
  }, []);

  // Combine all plugins
  const plugins = [toolbar, highlight, navigation, zoom, download, search, searchPluginInstance];

  return (
    <>
      {isPreview ? previewToolbar : fullscreenToolbar}
      <Worker workerUrl="/libs/pdfjs-dist@3.4.120.min.js">
        <Viewer
          key={id}
          fileUrl={fileUrl}
          initialPage={initialPageNumber}
          renderError={(error) => <PDFViewerError error={error} />}
          onPageChange={handlePageChange}
          onDocumentLoad={onDocumentLoadSuccess}
          plugins={plugins}
          renderLoader={renderLoader}
        />
      </Worker>
    </>
  );
};
