import { Checkbox, Position, Button } from '@consigli/facade';
import { useMyUser } from '@consigli/hooks';
import { Dispatch, SetStateAction } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';

interface OrgUserProps {
  control: Control<FieldValues>;
  setShowProjectPopup: Dispatch<SetStateAction<boolean>>;
}

export const OrgUser = (props: OrgUserProps) => {
  const { control, setShowProjectPopup } = props;
  const { t } = useTranslation();
  const {
    user: { organizationId },
  } = useMyUser();

  return (
    <>
      {organizationId && (
        <>
          <div className="text-base font-semibold text-day-neutral-subtle block">
            {t('invite-user.permissions')}
          </div>
          <Controller
            name="canCreateProjects"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                labelPosition={Position.RIGHT}
                label={t('invite-user.create-projects')}
                id={'canCreateProjects'}
                className="grid-cols-1"
              />
            )}
          />
        </>
      )}

      <div className="my-4">
        <div className="text-base font-semibold text-day-neutral-subtle block">
          {t('invite-user.project-access')}
        </div>
        <span>
          <Button
            rounded
            secondary
            onClick={() => setShowProjectPopup(true)}
            icon={FaPlus}
            iconPosition={Position.LEFT}
            className="my-4 text-sm"
          >
            {t('invite-user.add-project-access')}
          </Button>
        </span>
      </div>
    </>
  );
};
