import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import mascot from '@/assets/images/consigli-mascot.webp';

export const WaitingForAnalysis: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center">
      <p className="w-[400px] font-semibold text-day-neutral-dark">
        {t('dashboard.waiting-for-analysis-insight')}
      </p>
      <img src={mascot} alt="Consigli mascot" className="h-[80vh]"></img>
    </div>
  );
};
