import { useProjectId, useServiceName } from '@consigli/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { ViewerProvider } from '@/contexts/use-viewer-context';
import { Breadcrumb } from '@/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';

export const ServiceDetailWrapperPage: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const serviceName = useServiceName();
  return (
    <ViewerProvider>
      <Breadcrumb
        label={t(`services.${serviceName}`)}
        to={`/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}`}
      />
      <Outlet />
    </ViewerProvider>
  );
};
