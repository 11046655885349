import { type FC, type PropsWithChildren } from 'react';

import { PageHeader } from '@/layouts/page-header/page-header';

export const RootLayout: FC<PropsWithChildren> = ({ children }) => (
  <div className="w-screen min-h-screen flex flex-col bg-day-light-4">
    <PageHeader />
    <div className="flex flex-col w-full h-full relative flex-1">{children}</div>
  </div>
);
