import { Label, Checkbox } from '@consigli/facade';
import { ProjectConstructionType } from '@consigli/types';
import { type FC, useCallback, useMemo, useState } from 'react';
import {
  type Control,
  Controller,
  type ControllerRenderProps,
  type FieldErrors,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProjectInputs } from '@/util/types';

interface ConstructionTypeProps {
  control: Control<ProjectInputs>;
  errors: FieldErrors<ProjectInputs>;
}
export const ConstructionTypes: FC<ConstructionTypeProps> = ({ control, errors }) => {
  const { t } = useTranslation();

  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  const constructionTypeChoices = useMemo(
    () =>
      Object.values(ProjectConstructionType).map((value) => ({
        label: t(`project-construction-type.${value}`),
        value,
      })),
    [t],
  );

  const onConstructionTypesChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      field: ControllerRenderProps<ProjectInputs, 'constructionTypes'>,
    ) => {
      let valueCopy = [...checkedValues];
      valueCopy = valueCopy.includes(e.target.value)
        ? valueCopy.filter(function (ele) {
            return ele !== e.target.value;
          })
        : [...valueCopy, e.target.value];
      field.onChange(valueCopy);

      setCheckedValues(valueCopy);
    },
    [checkedValues],
  );

  return (
    <div>
      <Label text={t('project-creation.construction-type-label')} />
      <Controller
        name="constructionTypes"
        control={control}
        rules={{
          required: `${t('project-creation.construction-type-required')}*`,
          validate: (value) => {
            if (value.length === 0) return `${t('project-creation.construction-type-required')}*`;
          },
        }}
        render={({ field }) => (
          <div className="grid grid-cols-2 gap-2">
            {constructionTypeChoices.map(({ label, value }) => (
              <Checkbox
                id={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onConstructionTypesChange(event, field);
                }}
                key={value}
                value={value}
                label={label}
                checked={checkedValues.includes(value)}
              ></Checkbox>
            ))}
          </div>
        )}
      />

      {errors.constructionTypes && (
        <div className={'text-sm my-2 text-error-dark'}>{errors.constructionTypes.message}</div>
      )}
    </div>
  );
};
