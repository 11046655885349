import { useCeilingGridId, useCeilingGrids, useProjectId } from '@consigli/hooks';
import { CeilingType } from '@consigli/types';

import { PackageList } from './package-list';

export const CeilingGridPackageList = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();

  const { ceilingGrids } = useCeilingGrids({
    projectId,
    type: CeilingType.CEILING_GRID,
    page: 'all',
  });

  return <PackageList packages={ceilingGrids} id={ceilingGridId} />;
};
