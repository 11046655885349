import { Button, Checkbox, Position } from '@consigli/facade';
import { FC, useCallback, useMemo, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { RxCross1 } from 'react-icons/rx';

import { useCeilingViewerContext } from '../ceiling-viewer-context';

export const EditCeilingZValues: FC = () => {
  const {
    setShowEditMissingCeilingView,
    setAllFloors,
    activeFloorId,
    roomsWithMissingCeiling,
    rooms,
  } = useCeilingViewerContext();
  const { t } = useTranslation();

  const [updatedZValues, setUpdatedZValues] = useState<{ [key: number]: number }>({});
  const [ignoredRooms, setIgnoredRooms] = useState<{ [key: number]: boolean }>({});

  const handleIgnoreRoomChange = useCallback(
    (roomId: number, checked: boolean) => {
      setIgnoredRooms((prevIgnoredRooms) => ({
        ...prevIgnoredRooms,
        [roomId]: checked,
      }));
    },
    [setIgnoredRooms],
  );

  const handleZValueChange = useCallback(
    (roomId: number, value: string) => {
      setUpdatedZValues((prevValues) => ({
        ...prevValues,
        [roomId]: parseFloat(value),
      }));
    },
    [setUpdatedZValues],
  );

  const handleSave = useCallback(() => {
    // Need to replace comma with period since number input can accept both depending on locale
    const sanitizedZValues = Object.keys(updatedZValues).reduce(
      (acc, roomId) => {
        acc[Number(roomId)] = parseFloat(
          updatedZValues[Number(roomId)].toString().replace(',', '.'),
        );
        return acc;
      },
      {} as { [key: number]: number },
    );

    setAllFloors((prevFloors) => {
      return prevFloors.map((floor) => {
        if (floor.uuid === activeFloorId) {
          const updatedRooms = { ...floor.rooms };
          Object.keys(sanitizedZValues).forEach((roomId) => {
            if (updatedRooms[Number(roomId)]) {
              updatedRooms[Number(roomId)].lowerCeilingZ = sanitizedZValues[Number(roomId)];
            }
          });
          Object.keys(ignoredRooms).forEach((roomId) => {
            if (updatedRooms[Number(roomId)]) {
              updatedRooms[Number(roomId)].ignoreCeiling = ignoredRooms[Number(roomId)];
            }
          });
          return { ...floor, rooms: updatedRooms };
        }
        return floor;
      });
    });
    setUpdatedZValues({});
    setIgnoredRooms({});
    setShowEditMissingCeilingView(false);
  }, [
    updatedZValues,
    ignoredRooms,
    activeFloorId,
    setAllFloors,
    setUpdatedZValues,
    setIgnoredRooms,
    setShowEditMissingCeilingView,
  ]);

  const canSave = useMemo(() => {
    return roomsWithMissingCeiling.every(
      (room) => updatedZValues[room.roomId] !== undefined || ignoredRooms[room.roomId] === true,
    );
  }, [roomsWithMissingCeiling, updatedZValues, ignoredRooms]);

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="flex w-full h-full flex-col justify-center items-center mt-6">
        <h1 className="text-xl font-semibold text-day-neutral-dark text-center">
          {t('package-creation.ceiling-grid.edit-ceiling-heights')}
        </h1>
      </div>
      <div className="absolute top-4 right-4">
        <Button
          primary
          rounded
          onClick={() => setShowEditMissingCeilingView(false)}
          icon={RxCross1}
          iconColor="white"
          iconPosition={Position.RIGHT}
        >
          {t('package-creation.ceiling-grid.close')}
        </Button>
      </div>
      <div className="flex flex-col justify-center mt-10 w-3/4 p-4 ">
        <div className="max-h-[60vh] overflow-y-auto">
          <table className="table-auto w-full border-collapse max-h-[30%]">
            <thead className="sticky top-0 bg-day-light-4">
              <tr className="border-b border-day-primary">
                <th className="px-4 py-2 text-left text-sm">
                  {t('package-creation.ceiling-grid.room-id')}
                </th>
                <th className="px-4 py-2 text-left text-sm">
                  {t('package-creation.ceiling-grid.room-type')}
                </th>
                <th className="px-4 py-2 text-left text-sm">
                  {t('package-creation.ceiling-grid.lower-ceiling-height')}
                </th>
                <th className="px-4 py-2 text-left text-sm">
                  {t('package-creation.ceiling-grid.ignore-room')}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(rooms).map((room) => (
                <tr key={room.roomId} className="border-b border-day-primary">
                  <td className="px-4 py-2 text-sm">{room.roomId}</td>
                  <td className="px-4 py-2 text-sm">{room.roomCategory ?? 'No type'}</td>
                  <td className="px-4 py-2 text-sm">
                    <input
                      type="number"
                      step="0.01"
                      value={updatedZValues[room.roomId] ?? room.lowerCeilingZ}
                      onChange={(e) => handleZValueChange(room.roomId, e.target.value)}
                      className="w-[70px] px-2 py-1 border rounded mr-1"
                      disabled={
                        !roomsWithMissingCeiling.some(
                          (missingRoom) => missingRoom.roomId === room.roomId,
                        )
                      }
                    />
                    m
                  </td>
                  <td className="px-4 py-2 text-sm">
                    <Checkbox
                      id={room.roomId.toString()}
                      label=""
                      checked={ignoredRooms[room.roomId] ?? false}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleIgnoreRoomChange(room.roomId, event.target.checked)
                      }
                      disabled={
                        !roomsWithMissingCeiling.some(
                          (missingRoom) => missingRoom.roomId === room.roomId,
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <p className="text-day-neutral-subtle">
            {t('package-creation.ceiling-grid.fill-in-missing-values')}
          </p>
          <Button primary rounded onClick={handleSave} disabled={!canSave}>
            {t('package-creation.ceiling-grid.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
