import { createOAuthConfiguration } from '@consigli/oauth';
import { notNull } from '@consigli/utils';
import { oneOf } from '@consigli/utils';
export var ViteMode;
(function (ViteMode) {
    ViteMode["TEST"] = "test";
    ViteMode["LOCAL_DEV"] = "local-dev";
    ViteMode["LOCAL_UAT"] = "local-uat";
    ViteMode["DEVELOPMENT"] = "development";
    ViteMode["UAT"] = "uat";
    ViteMode["PRODUCTION"] = "production";
})(ViteMode || (ViteMode = {}));
export const config = {
    MODE: oneOf(notNull(import.meta.env.MODE, 'meta.env.MODE'), Object.values(ViteMode)),
    OAUTH_ENDPOINT: notNull(import.meta.env.VITE_OAUTH_ENDPOINT, 'meta.env.VITE_OAUTH_ENDPOINT'),
    DEV: notNull(import.meta.env.DEV, 'meta.env.DEV'),
    PROD: notNull(import.meta.env.PROD, 'meta.env.PROD'),
    CORE_API_BASE_URL: notNull(import.meta.env.VITE_CORE_API_BASE_URL, 'meta.env.VITE_CORE_API_BASE_URL'),
    CHAT_API_BASE_URL: notNull(import.meta.env.VITE_CHAT_API_BASE_URL, 'meta.env.VITE_CHAT_API_BASE_URL'),
    WEBAPP_URL: notNull(import.meta.env.VITE_WEBAPP_URL, 'meta.env.VITE_WEBAPP_URL'),
    WEBAPP_CLIENT_ID: notNull(import.meta.env.VITE_WEBAPP_CLIENT_ID, 'meta.env.VITE_WEBAPP_CLIENT_ID'),
};
export const authConfig = createOAuthConfiguration({
    webappClientId: config.WEBAPP_CLIENT_ID,
    webappUrl: config.WEBAPP_URL,
    oauthEndpoint: config.OAUTH_ENDPOINT,
});
