import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useLayoutId,
  useLayoutFiles,
  useGetLayoutOptimizationQuery,
  useMyUser,
} from '@consigli/hooks';
import { convertFileToDocument } from '@consigli/types';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminBanner } from '@/components/admin-banner';
import { WaitingForAnalysis } from '@/components/waiting-for-analysis';
import { WorkspaceProvider } from '@/contexts/use-workspace-context';
import { Breadcrumb } from '@/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';

import EngineeringInput from './input';

export const EngineeringFileList: FC = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const { t } = useTranslation();
  const { user } = useMyUser();

  const { files, isLoading, isFetching } = useLayoutFiles({
    projectId,
    layoutId,
    page: 'all',
  });
  const { data: layout } = useGetLayoutOptimizationQuery({ projectId, layoutId });

  const isPublished = layout ? layout.published : false;

  const waitingForAnalysis = useMemo(() => {
    if (user.isSuperuser) {
      return false;
    }
    if (isPublished) {
      return false;
    }
    return true;
  }, [isPublished, user.isSuperuser]);

  return (
    <>
      <Breadcrumb label={t('optimization-tabs.input')} />
      <WorkspaceProvider>
        <WorkspaceLayout hideTabs={waitingForAnalysis}>
          {waitingForAnalysis ? (
            <WaitingForAnalysis />
          ) : isLoading ? (
            <LoadingArea title={t('document-list.loading')} />
          ) : files.length > 0 ? (
            <div className="flex flex-col">
              <AdminBanner user={user} published={isPublished} />
              <EngineeringInput
                files={files.map((file) => convertFileToDocument(file))}
                isFetching={isFetching}
              ></EngineeringInput>
            </div>
          ) : (
            <div className="flex justify-center items-center w-full h-full text-[24px]">
              {t('document-list.no-documents')}
            </div>
          )}
        </WorkspaceLayout>
      </WorkspaceProvider>
    </>
  );
};
