import { useBreadcrumbContext } from '@/layouts/breadcrumb/use-breadcrumb-context';

export const Breadcrumbs = () => {
  const [, setPortalNode] = useBreadcrumbContext();
  return (
    <div className="flex flex-row grow">
      <div
        className="md:flex flex-row grow gap-1.5 max-w-sm lg:max-w-lg xl:max-w-3xl 2xl:max-w-5xl items-baseline"
        ref={setPortalNode}
      />
    </div>
  );
};
