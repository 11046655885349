import { FileUpload } from '@consigli/facade';
import {
  ExtendedFile,
  useGetPackagesByServiceIdQuery,
  useProjectId,
  useServiceName,
} from '@consigli/hooks';
import { PipelineName, ServiceId, ServiceName } from '@consigli/types';
import { useCallback, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ServiceIdIndexValue } from '@/routes';

import { IterationName } from '../components/iteration-name';
import { NeedClassification } from '../components/need-classification';
import { RequiredInsightFiles } from '../components/required-file-types';
import { SelectLanguage } from '../components/select-language';
import { UploadIndexFiles } from '../components/upload-index-files';
import { useFetchValidateName } from '../components/user-input';
import { WizardAnalyzeButton } from '../components/wizard-analyze-button';
import { WizardLayout } from '../components/wizard-layout';
import { useCreateInsightPackage } from '../hooks/use-create-insight-package';
import { useUploadProgress } from '../hooks/use-upload-progress';

export const InsightWizard = () => {
  const [formData] = useState<FieldValues>({
    iterationName: '',
    language: '',
    needClassification: false,
  });
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<ExtendedFile[]>([]);
  const [indexFiles, setIndexFiles] = useState<ExtendedFile[]>([]);
  const projectId = useProjectId();
  const serviceName = useServiceName();
  const serviceId = ServiceIdIndexValue[serviceName] as ServiceId;
  const [isProcessing, setIsProcessing] = useState(false);
  const [showMessagePanel, setShowMessagePanel] = useState(true);
  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);
  const { createDocumentPackage, uploadFiles, startAnalysis } = useCreateInsightPackage({
    setCurrentCounter,
    setTargetCounter,
  });

  const { isUploading, progress } = useUploadProgress(currentCounter, targetCounter);

  const methods = useForm<FieldValues>({
    defaultValues: formData,
  });
  const { getValues, handleSubmit } = methods;
  const [uploadIndexFiles, setUploadIndexFiles] = useState<boolean>(getValues('uploadIndexFiles'));

  const onAnalyze = useCallback(async () => {
    // step 1 , create package
    try {
      setIsProcessing(true);
      const createdPackageId = await createDocumentPackage(
        serviceId,
        getValues('iterationName'),
        getValues('language'),
        getValues('needClassification'),
      );
      if (createdPackageId === undefined) {
        return;
      }
      let combinedFiles: (File & { isDrawingIndexList?: boolean })[] = [];
      if (uploadIndexFiles) {
        const modifiedIndexFiles = indexFiles.map((file) => {
          // Directly add the new property to the File object using type assertion
          const extendedFile = file as File & { isDrawingIndexList?: boolean };
          extendedFile.isDrawingIndexList = true;
          return extendedFile;
        });
        combinedFiles = [...localFiles, ...modifiedIndexFiles];
      } else {
        combinedFiles = localFiles;
      }

      // step 2, upload files from local state of files, , then initiate analysis phase
      await uploadFiles(combinedFiles, createdPackageId);

      // step 3, start pipeline
      await startAnalysis(createdPackageId, PipelineName.DEFAULT);
      toast.success(t('package-creation.success-analyzed'));
    } catch (error) {
      toast.error(t(`package-creation.failed-analyzed`));
    } finally {
      setIsProcessing(false);
    }
  }, [
    getValues,
    createDocumentPackage,
    serviceId,
    uploadFiles,
    localFiles,
    startAnalysis,
    t,
    indexFiles,
    uploadIndexFiles,
  ]);

  const { validateName } = useFetchValidateName(useGetPackagesByServiceIdQuery, {
    projectId,
    serviceId,
  });

  const FormComponent = (
    <>
      <FormProvider {...methods}>
        <form className="w-full" onSubmit={handleSubmit(onAnalyze)}>
          <div className="flex flex-col" translate="no">
            <IterationName
              validateName={validateName}
              label={t('package-creation.analysis-name')}
              errorMessage={`${t('package-creation.analysis-name-empty')}*`}
            />
          </div>
          <SelectLanguage />
          {serviceName === ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE && (
            <>
              <UploadIndexFiles
                uploadIndexFiles={uploadIndexFiles}
                setUploadIndexFiles={setUploadIndexFiles}
              />
              {uploadIndexFiles && (
                <>
                  <FileUpload
                    files={indexFiles}
                    setFiles={setIndexFiles}
                    dropAreaLabel={t('document-upload.drag-n-drop-here')}
                    dropAreaClassName="mt-4 h-48 w-full"
                    fileButtonLabel={t('document-upload.browse-files')}
                    folderButtonLabel={t('document-upload.browse-folders')}
                    uploadListLabel={t('document-upload.files')}
                    zeroByteFilesMessage={t('document-upload.file-zero-bytes')}
                    showMessagePanel={showMessagePanel}
                    setShowMessagePanel={setShowMessagePanel}
                  ></FileUpload>
                </>
              )}
            </>
          )}
          <p className="mb-4 mt-4 text-base font-semibold text-day-neutral-subtle">
            {t('package-creation.upload-files')}
          </p>
          {serviceName === ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE && <NeedClassification />}
          <FileUpload
            files={localFiles}
            setFiles={setLocalFiles}
            dropAreaLabel={t('document-upload.drag-n-drop-here')}
            dropAreaClassName="mt-4 h-48 w-full"
            fileButtonLabel={t('document-upload.browse-files')}
            folderButtonLabel={t('document-upload.browse-folders')}
            uploadListLabel={t('document-upload.files')}
            zeroByteFilesMessage={t('document-upload.file-zero-bytes')}
            showMessagePanel={showMessagePanel}
            setShowMessagePanel={setShowMessagePanel}
          >
            <div className="fc-w-full">
              <WizardAnalyzeButton localFiles={localFiles} type="submit"></WizardAnalyzeButton>
            </div>
          </FileUpload>
        </form>
      </FormProvider>
    </>
  );

  const messageHeader = showMessagePanel ? (
    <RequiredInsightFiles setShowMessagePanel={setShowMessagePanel}></RequiredInsightFiles>
  ) : (
    <></>
  );

  return (
    <WizardLayout
      stepsRenderer={FormComponent}
      isProcessing={isProcessing}
      messageHeader={messageHeader}
      isUploading={isUploading}
      progress={progress}
    />
  );
};
