import { TextInput, Position, MultipleRadioButton } from '@consigli/facade';
import { useMyUser } from '@consigli/hooks';
import { OrganizationRole, ProjectRole } from '@consigli/types';
import { useEffect, useMemo } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircle } from 'react-icons/io';

interface InviteFieldsProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}

export const InviteFields = (props: InviteFieldsProps) => {
  const { register, control, getValues, setValue, errors, watch } = props;
  const {
    user: { isOrgAdmin, organizationId, projectAccess },
  } = useMyUser();

  const { t } = useTranslation();
  const organizationRole = watch('organizationRole');
  const organizationOptions = useMemo(
    () => [
      {
        label: t('invite-user.admin'),
        value: OrganizationRole.ADMIN,
      },
      {
        label: t('invite-user.user'),
        value: OrganizationRole.USER,
      },
    ],
    [t],
  );

  const showAdminInfo = useMemo(() => {
    return organizationRole === undefined || organizationRole === '';
  }, [organizationRole]);

  useEffect(() => {
    if (!isOrgAdmin && projectAccess.some((access) => access.role === ProjectRole.ADMIN)) {
      setValue('organizationRole', OrganizationRole.USER);
    }
  }, [isOrgAdmin, setValue, organizationId, projectAccess]);

  return (
    <div>
      <div className="mt-4 mb-6">
        <TextInput
          label={t('invite-user.email')}
          className={`my-1 rounded`}
          placeholder={t('invite-user.enter-email-placeholder')}
          {...register('recipient', {
            required: `${t('invite-user.email-required')}*`,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: `${t('invite-user.valid-email')}*`,
            },
          })}
        />
        {errors.recipient && (
          <div className={'text-sm my-2 text-error-dark'}>{errors.recipient.message as string}</div>
        )}
      </div>
      {isOrgAdmin && (
        <>
          <MultipleRadioButton
            data={organizationOptions}
            label={t('invite-user.role')}
            id="organizationRole"
            control={control}
            register={register}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            labelPosition={Position.LEFT}
            errorMessage={`${t('user_administration.id-required')}*`}
          />
          {showAdminInfo && (
            <div className="flex mt-2 opacity-50">
              <IoIosInformationCircle />
              <p className="text-xs ml-1">
                <b>{t('invite-user.role-admin')}</b> {t('invite-user.role-admin-description')}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
