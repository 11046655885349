import { CustomSelect, SelectOption } from '@consigli/facade';
import { Country } from '@consigli/types';
import { useMemo, type FC, useCallback } from 'react';
import { type Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProjectInputs } from '@/util/types';

interface ProjectCountryProps {
  control: Control<ProjectInputs>;
}

export const ProjectCountry: FC<ProjectCountryProps> = ({ control }) => {
  const { t } = useTranslation();

  const countryChoices: SelectOption[] = useMemo(
    () => [
      {
        label: t('country.norway'),
        value: Country.NORWAY,
      },
      {
        label: t('country.sweden'),
        value: Country.SWEDEN,
      },
      {
        label: t('country.denmark'),
        value: Country.DENMARK,
      },
      {
        label: t('country.iceland'),
        value: Country.ICELAND,
      },
      {
        label: t('country.finland'),
        value: Country.FINLAND,
      },
      {
        label: t('country.japan'),
        value: Country.JAPAN,
      },
      {
        label: t('country.france'),
        value: Country.FRANCE,
      },
      {
        label: t('country.netherlands'),
        value: Country.NETHERLANDS,
      },
      {
        label: t('country.united-kingdom'),
        value: Country.UNITED_KINGDOM,
      },
      {
        label: t('country.united-states'),
        value: Country.UNITED_STATES,
      },
      {
        label: t('country.canada'),
        value: Country.CANADA,
      },
      {
        label: t('country.hungary'),
        value: Country.HUNGARY,
      },
      {
        label: t('country.germany'),
        value: Country.GERMANY,
      },
      {
        label: t('country.vietnam'),
        value: Country.VIETNAM,
      },
    ],
    [t],
  );

  const { field, fieldState } = useController({
    name: 'country',
    control,
    rules: { required: `${t('project-creation.country-required')}*` },
  });

  const onCountryChange = useCallback(
    (selectedOptions: SelectOption[]): void => {
      field.onChange(selectedOptions.length > 0 ? selectedOptions[0].value : null);
    },
    [field],
  );

  return (
    <div>
      <CustomSelect
        options={countryChoices}
        onChange={onCountryChange}
        value={field.value ? countryChoices.filter((option) => option.value === field.value) : []}
        placeholder={t('project-creation.country-placeholder')}
        label={t('project-creation.country-label')}
        noOptionsMessage={t('custom-select.no-options')}
        className="w-[400px]"
        isClearable
      />
      {fieldState.invalid && (
        <div className={'text-sm my-1 text-error-dark'}>{fieldState.error?.message}</div>
      )}
    </div>
  );
};
