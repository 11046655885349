import { FC } from 'react';

import { Tab } from '@/components/tab';

import { useCeilingViewerContext } from '../ceiling-viewer-context';
import { useFloorSwitcher } from '../hooks/use-floor-switcher';

export const FloorPicker: FC = () => {
  const { selectedFloors, activeFloorId } = useCeilingViewerContext();
  const { handleFloorChange } = useFloorSwitcher();

  return (
    <div className="flex mb-4 justify-center">
      {selectedFloors.length > 0 &&
        selectedFloors.map((floor) => (
          <Tab
            key={floor.uuid}
            label={floor.floorName}
            onClick={() => handleFloorChange(floor.uuid)}
            selected={floor.uuid === activeFloorId}
          />
        ))}
    </div>
  );
};
