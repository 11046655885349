import { ActionStatus, Finding, FindingCommenter } from '@consigli/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import { LuSend } from 'react-icons/lu';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TbFileSearch } from 'react-icons/tb';

import { ServiceHeader } from '@/components/service-header';

import { ExportButton } from './export-button';
import { useFindingsContext } from './findings-context';
import { FindingsList } from './findings-list';
import { FindingBlob } from './findings-wrapper';

type MapActionNameToStatesType = {
  [key in string]: {
    icon: React.FC<React.SVGProps<SVGSVGElement>> | undefined;
    action: ActionStatus;
    title: string;
  };
};

export const mapActionNameToStates: MapActionNameToStatesType = {
  '1': {
    icon: RiDeleteBinLine,
    action: ActionStatus.NOT_RELEVANT,
    title: 'findings.states.notrelevant',
  },
  '2': {
    icon: FiClock,
    action: ActionStatus.UNADDRESSED,
    title: 'findings.states.active',
  },
  '3': {
    icon: LuSend,
    action: ActionStatus.WAITING,
    title: 'findings.states.waiting',
  },
  '4': {
    icon: FaRegCheckCircle,
    action: ActionStatus.PROCESSED,
    title: 'findings.states.processed',
  },
};

interface FindingsContentProps {
  findings: Finding[];
  findingsCount: number;
  uniqueBlobs: FindingBlob[];
  commenters: FindingCommenter[];
  actionTabs: string[];
  actionCount: Record<string, number>;
  itemsOnPage: number;
  isFetchingFindings: boolean;
  isFetchingActionCount: boolean;
  downloadFindingsForExport: (isFilterNeeded: boolean) => Promise<Finding[]>;
  isDownloadingFindingsForExport: boolean;
}
export const FindingsContent = ({
  findings,
  findingsCount,
  uniqueBlobs,
  commenters,
  actionTabs,
  actionCount,
  itemsOnPage,
  isFetchingFindings,
  isFetchingActionCount,
  downloadFindingsForExport,
  isDownloadingFindingsForExport,
}: FindingsContentProps) => {
  const { t } = useTranslation();
  const { selectedConflictCategory, selectedConflictType } = useFindingsContext();

  const getTitleMessage = useMemo(() => {
    if (selectedConflictCategory && selectedConflictType) {
      return t(`dashboard.conflict-title.${selectedConflictType}`);
    } else if (selectedConflictCategory) {
      return t(`dashboard.conflict-category.${selectedConflictCategory}`);
    }
    return t('findings.total_findings');
  }, [selectedConflictCategory, selectedConflictType, t]);

  const totalActionCount = useMemo(() => {
    return actionCount ? Object.values(actionCount).reduce((a, b) => a + b, 0) : 0;
  }, [actionCount]);

  return (
    <div className="p-0 lg:px-8 flex-1 bg-day-light-3 transition duration-500">
      <div className="flex flex-col">
        <div className="px-4 py-2 xl:py-6 relative">
          <div className="flex items-center justify-between">
            <ServiceHeader
              icon={TbFileSearch}
              count={totalActionCount}
              message={t('findings.findings')}
              title={getTitleMessage}
            />
            <div className="px-4">
              <ExportButton
                isFilterNeeded={true}
                downloadFindings={downloadFindingsForExport}
                isDownloadingFindings={isDownloadingFindingsForExport}
              />
            </div>
          </div>
          <FindingsList
            findings={findings}
            uniqueBlobs={uniqueBlobs}
            commenters={commenters}
            findingsCount={findingsCount}
            actionTabs={actionTabs}
            actionCount={actionCount}
            itemsOnPage={itemsOnPage}
            isFetchingFindings={isFetchingFindings}
            isFetchingActionCount={isFetchingActionCount}
          />
        </div>
      </div>
    </div>
  );
};
