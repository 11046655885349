import { Button } from '@consigli/facade';
import { useKeyListener } from '@consigli/hooks';
import { DocumentType } from '@consigli/types';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { DownloadFileButton } from '@/components/download-file-button';
import { ImageContainer } from '@/components/image-viewer/image-container';
import { ViewerMode, useViewerContext } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { Route } from '@/routes';
import { isMsWordType, isImageDocumentType, isValidDocumentType } from '@/util/document-check';

import { DocumentViewer } from './document-viewer';
import { DoublePdfViewer } from './double-pdf';
import { isDocumentDoubleViewerCapable } from './fullscreen/finding/container';
import { FindingsNavigationButtons } from './fullscreen/finding/finding-naviagtion-buttons';
import { SinglePdfViewer } from './single-pdf';

export const isOptimizationService = (serviceName: string | undefined) => {
  return (
    serviceName === Route.FLOOR_PLAN ||
    serviceName === Route.CEILING_PLAN ||
    serviceName === Route.PLANT_ROOM ||
    serviceName === Route.SPACE_ANALYSIS ||
    serviceName === Route.PARKING ||
    serviceName === Route.REPORTS
  );
};

export const DocumentPreviewContainer = () => {
  const { t } = useTranslation();
  const { document, mode } = useViewerContext();
  const { openDocumentViewer, close } = useViewer();
  const [previewMode, setPreviewMode] = useState<ViewerMode>();
  const previewRef = useRef<HTMLDivElement>(null);
  const delayedCloseFlag = useRef(false);
  const { serviceName } = useParams();
  useEffect(() => {
    if (mode === ViewerMode.DocumentPreview && !delayedCloseFlag.current) {
      setTimeout(() => {
        setPreviewMode(ViewerMode.DocumentPreview);
      }, 70);
    } else if (mode === ViewerMode.ReturnToDocumentPreview && !delayedCloseFlag.current) {
      setPreviewMode(ViewerMode.ReturnToDocumentPreview);
    }
    return () => {
      delayedCloseFlag.current = false;
    };
  }, [mode]);

  const delayedClose = useCallback(() => {
    setPreviewMode(ViewerMode.Closed);
    delayedCloseFlag.current = true;
    setTimeout(() => {
      close();
    }, 700);
  }, [close]);

  useKeyListener('Escape', delayedClose);

  const PreviewComponent = useCallback(() => {
    if (!document) {
      return null;
    }
    if (isValidDocumentType(document.name)) {
      if (isImageDocumentType(document.name)) {
        return <ImageContainer document={document} isPreview={true} />;
      }
      if (
        (isOptimizationService(serviceName) && isMsWordType(document.name)) ||
        (isMsWordType(document.name) &&
          !document.convertedPdfFileId &&
          document.type !== DocumentType.FINDING)
      ) {
        return <DocumentViewer document={document} isPreview={true} />;
      }

      return isDocumentDoubleViewerCapable(document) ? (
        <DoublePdfViewer document={document} isPreview={true} />
      ) : (
        <SinglePdfViewer document={document} isPreview={true} />
      );
    } else {
      return (
        <div className="h-full w-full flex flex-col items-center justify-center p-4 space-y-2">
          <p>{t('findings.file-type-not-supported')}</p>
          <DownloadFileButton document={document} size={24} className="rounded-full" />
        </div>
      );
    }
  }, [document, serviceName, t]);

  return (
    <div
      ref={previewRef}
      className={clsx(
        'shadow fixed z-[50] border-l border-[#ebebeb] top-14 right-[-35vw] w-[35vw] pb-24 transition-all duration-700 ease-in-out',
        previewMode == ViewerMode.DocumentPreview && 'translate-x-[-35vw]',
        previewMode == ViewerMode.ReturnToDocumentPreview && 'translate-x-[-35vw] transition-none',
      )}
    >
      <div className="flex flex-col text-white bg-night-light-3 h-full relative">
        <div className="flex flex-row py-1">
          <div className="ml-auto">
            {document && isValidDocumentType(document.name) && (
              <Button
                tertiary
                onClick={() => {
                  openDocumentViewer(document, ViewerMode.DocumentFullscreen);
                }}
                className="fa-solid fa-expand fa-lg h-10 w-10 pr-4 text-white hover:bg-night-light-3"
                title={t('findings.buttons.fullscreen')}
              />
            )}
            <Button
              tertiary
              onClick={delayedClose}
              className="fa-solid fa-x fa-lg h-10 w-10 pr-8 text-white hover:bg-night-light-3"
              title={t('findings.buttons.close')}
            />
          </div>
        </div>
        {document?.type === DocumentType.FINDING ? (
          <>
            <div className="flex justify-center overflow-y-auto h-[calc(100vh-10.5rem)]">
              <PreviewComponent />
            </div>
            <div className="flex justify-end items-right p-4 bg-night-light-3 border-day-primary">
              <FindingsNavigationButtons mode={ViewerMode.DocumentPreview} />
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center overflow-y-auto h-[calc(100vh-7.5rem)] mb-4">
              <PreviewComponent />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
