import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type DocumentListContext = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
};

export const DocumentListContext = createContext<DocumentListContext | null>(null);

export const useDocumentsContext = () => {
  const ctx = useContext(DocumentListContext);

  if (!ctx) {
    throw new Error('Missing Document List Provider in tree');
  }

  return ctx;
};

export const DocumentListProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <DocumentListContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </DocumentListContext.Provider>
  );
};
