import { Checkbox } from '@consigli/facade';
import { convertBlobToDocument } from '@consigli/types';
import { useCallback } from 'react';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { ViewerMode } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { isIFCDocumentType } from '@/util/document-check';
import { FolderDocumentsType } from '@/util/types';

import { FoldersCard } from './folders-card';

interface FoldersCardListProps {
  pageRecords: FolderDocumentsType[];
  filterItems: (items: FolderDocumentsType[]) => FolderDocumentsType[];
  handleCheckboxClick: (record: FolderDocumentsType) => void;
}

export const FoldersCardList = ({
  pageRecords,
  filterItems,
  handleCheckboxClick,
}: FoldersCardListProps) => {
  const { openDocumentViewer } = useViewer();
  const { selectedCard, setSelectedCard } = useFileTreeContext();

  const handleCardClick = useCallback(
    (file: FolderDocumentsType) => {
      setSelectedCard(file.id);
      if (isIFCDocumentType(file.name)) {
        openDocumentViewer(convertBlobToDocument(file), ViewerMode.DocumentFullscreen);
      } else {
        openDocumentViewer(convertBlobToDocument(file), ViewerMode.DocumentPreview);
      }
    },
    [openDocumentViewer, setSelectedCard],
  );

  return (
    <div className="px-4 h-[calc(100vh-25rem)] overflow-auto">
      {filterItems(pageRecords).map((file) => (
        <div key={file.id} className="flex items-center">
          <div className="mr-4">
            <Checkbox
              value={file.id}
              label=""
              id={file.id}
              checked={file.checked}
              onChange={() => handleCheckboxClick(file)}
            />
          </div>
          <div className="flex-1">
            <FoldersCard
              file={file}
              selected={selectedCard === file.id}
              onClick={() => handleCardClick(file)}
              handleCheckboxClick={handleCheckboxClick}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
