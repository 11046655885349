import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { userSchema } from './user';
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["PUBLIC_REAL_ESTATE_OWNER"] = "PUBLIC_REAL_ESTATE_OWNER";
    OrganizationType["PRIVATE_REAL_ESTATE_OWNER"] = "PRIVATE_REAL_ESTATE_OWNER";
    OrganizationType["CONTRACTOR"] = "CONTRACTOR";
    OrganizationType["SUBCONTRACTOR"] = "SUBCONTRACTOR";
    OrganizationType["CONSULTANT"] = "CONSULTANT";
})(OrganizationType || (OrganizationType = {}));
export const organizationTypeSchema = z.nativeEnum(OrganizationType);
export var OrganizationRole;
(function (OrganizationRole) {
    OrganizationRole["ADMIN"] = "ADMIN";
    OrganizationRole["USER"] = "USER";
})(OrganizationRole || (OrganizationRole = {}));
export var Services;
(function (Services) {
    Services["TENDER_DOCUMENTS"] = "TENDER_DOCUMENTS";
    Services["OFFER"] = "OFFER";
    Services["TENANT_SPECIFICATION"] = "TENANT_SPECIFICATION";
    Services["TENANT_REQUIREMENTS"] = "TENANT_REQUIREMENTS";
    Services["DUE_DILIGENCE"] = "DUE_DILIGENCE";
    Services["MANAGEMENT_OPERATIONS_MAINTENANCE"] = "MANAGEMENT_OPERATIONS_MAINTENANCE";
    Services["PLANT_ROOM"] = "PLANT_ROOM";
    Services["CEILING_PLAN"] = "CEILING_PLAN";
    Services["FLOOR_PLAN"] = "FLOOR_PLAN";
    Services["SPACE_ANALYSIS"] = "SPACE_ANALYSIS";
    Services["PARKING"] = "PARKING";
    Services["REPORTS"] = "REPORTS";
    Services["STRUCTURAL"] = "STRUCTURAL";
    Services["CEILING_GRID"] = "CEILING_GRID";
})(Services || (Services = {}));
export const organizationSchema = z
    .object({
    id: z.string().uuid(),
    name: z.string().min(1),
    type: organizationTypeSchema,
    owned_by: userSchema.innerType().shape.id,
    created_at: z.string(),
    domain: z.string().optional(),
    available_services: z.array(z.nativeEnum(Services)).optional(),
    sso_enabled: z.boolean(),
    email_domains: z.array(z.string()),
})
    .strict()
    .transform(mapToCamelCase);
export const createOrganizationRequestSchema = organizationSchema
    .innerType()
    .pick({
    name: true,
    type: true,
    domain: true,
    available_services: true,
    sso_enabled: true,
    email_domains: true,
})
    .strict()
    .transform(mapToCamelCase);
export const getOrganizationsResponseSchema = z.array(organizationSchema).transform(mapToCamelCase);
