import { Spinner } from '@consigli/facade';
import {
  useProjectId,
  useLayoutId,
  useServiceName,
  useGetLayoutOptimizationResultsQuery,
} from '@consigli/hooks';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { Route } from '@/routes';

// This component is used to navigate to the correct engineering page when switching packages
// If the layout has results, it will navigate to the results page
// If not, it will navigate to the documents page
export const EngineeringNavigationWrapper = () => {
  const projectId = useProjectId();
  const layoutId = useLayoutId();
  const serviceName = useServiceName();
  const navigate = useNavigate();

  const { data: layoutResults } = useGetLayoutOptimizationResultsQuery({
    projectId,
    layoutId,
    page: 'all',
  });

  const hasResults = useMemo(() => {
    return layoutResults ? layoutResults.results.length > 0 : undefined;
  }, [layoutResults]);

  const navRoute = useMemo(() => {
    return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${layoutId}/${hasResults ? Route.RESULTS : Route.INPUT}`;
  }, [projectId, serviceName, layoutId, hasResults]);

  useEffect(() => {
    if (hasResults !== undefined) {
      navigate(navRoute, { replace: true });
    }
  }, [hasResults, navRoute, navigate]);

  return <Spinner size="large" />;
};
