import type { FC, ReactNode } from 'react';

interface CenteredContentProps {
  children: ReactNode;
}

export const CenteredLayout: FC<CenteredContentProps> = ({ children }) => (
  <div className="flex">
    <div className="w-full max-w-[2000px] mx-auto">{children}</div>
  </div>
);
