import { FC } from 'react';
import { HiOutlineMail, HiOutlineUser } from 'react-icons/hi';

import { Tab } from '@/components/tab';

import { UserManagementTab } from './user-management-page';

interface UserManagementTabsProps {
  inviteCount: number;
  userCount: number;
  keys: UserManagementTab[];
  selectedTab: UserManagementTab;
  handleTabClick: (tab: UserManagementTab) => void;
}

export const UserManagementTabs: FC<UserManagementTabsProps> = ({
  inviteCount,
  userCount,
  keys,
  selectedTab,
  handleTabClick,
}) => {
  const tabIcons = {
    [UserManagementTab.USERS]: HiOutlineUser,
    [UserManagementTab.INVITES]: HiOutlineMail,
  };
  const tabCounts = {
    [UserManagementTab.USERS]: userCount,
    [UserManagementTab.INVITES]: inviteCount,
  };
  return (
    <div className="flex mb-4">
      {keys.map((tab: UserManagementTab) => (
        <Tab
          key={tab}
          label={`user-management.tab.${tab.toLowerCase()}`}
          selected={selectedTab === tab}
          onClick={() => {
            handleTabClick(tab);
          }}
          count={tabCounts[tab]}
          icon={tabIcons[tab]}
        />
      ))}
    </div>
  );
};
