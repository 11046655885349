import { Button, CustomSelect, SelectOption } from '@consigli/facade';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSelection } from 'react-icons/bi';
import { RxCross2 } from 'react-icons/rx';
import { toast } from 'react-toastify';

import { useCeilingViewerContext } from '../ceiling-viewer-context';
import { RoomCategory, RoomType } from '../types';

export const MissingRoomType: FC = () => {
  const { t } = useTranslation();
  const { rooms, selectedRooms, setSelectedRooms, activeFloorId, setAllFloors } =
    useCeilingViewerContext();
  const [selectedRoomType, setSelectedRoomType] = useState<RoomCategory>();

  const roomCategoryOptions: SelectOption[] = Object.values(RoomCategory).map((category) => ({
    label: t('package-creation.ceiling-grid.room-types.' + category),
    value: category,
  }));

  const unassignedRoomCount = useMemo(() => {
    return Object.values(rooms).filter((room) => !room.roomCategory).length;
  }, [rooms]);

  const handleDeselectRoom = useCallback(
    (roomId: number) => {
      setSelectedRooms((prevSelectedRooms) => {
        const updatedRooms = { ...prevSelectedRooms };
        delete updatedRooms[roomId];
        return updatedRooms;
      });
    },
    [setSelectedRooms],
  );

  const handleSelectedRoomTypeChange = (selectedOptions: SelectOption[]) => {
    if (selectedOptions.length > 0) {
      setSelectedRoomType(selectedOptions[0].value as RoomCategory);
    } else {
      setSelectedRoomType(undefined);
    }
  };

  const handleUpdateRoomType = useCallback(() => {
    if (!selectedRoomType) {
      toast.error('Please select a room type');
      return;
    }

    try {
      setAllFloors((prevFloors) => {
        return prevFloors.map((floor) => {
          if (floor.uuid === activeFloorId) {
            const updatedRooms = { ...floor.rooms };
            Object.keys(selectedRooms).forEach((roomId) => {
              if (updatedRooms[roomId]) {
                updatedRooms[roomId].roomCategory = selectedRoomType;
              }
            });
            return { ...floor, rooms: updatedRooms };
          }
          return floor;
        });
      });
      setSelectedRoomType(undefined);
      setSelectedRooms({});
      toast.success(t('package-creation.ceiling-grid.room-type-update-success'));
    } catch (error) {
      toast.error('Failed to update room type');
    }
  }, [selectedRoomType, setAllFloors, setSelectedRooms, t, activeFloorId, selectedRooms]);

  return (
    <div className="bg-white shadow-md rounded-md px-6 py-4">
      <h2 className="text-xl font-bold">{t('package-creation.ceiling-grid.missing-room-type')}</h2>
      {unassignedRoomCount !== 0 && (
        <p className="text-sm mt-2">
          {t('package-creation.ceiling-grid.unassigned-rooms')}: {unassignedRoomCount}
        </p>
      )}
      <div className="border border-day-light-1 border-dashed rounded mt-2 p-2">
        {Object.keys(selectedRooms).length === 0 && (
          <div className="flex flex-col justify-center items-center p-2 text-center">
            <BiSelection size={30} />
            <p className="text-sm">{t('package-creation.ceiling-grid.click-to-select')}</p>
          </div>
        )}
        <div className="flex flex-wrap gap-2">
          {Object.values(selectedRooms).map((room: RoomType) => (
            <div
              key={room.roomId}
              className="bg-day-light-1 rounded-xs py-1 px-2 flex justify-between items-center"
            >
              <p className="text-sm">
                {room.roomId} -{' '}
                {room.roomCategory
                  ? t('package-creation.ceiling-grid.room-types.' + room.roomCategory)
                  : t('package-creation.ceiling-grid.unassigned')}
              </p>
              <Button
                tertiary
                className="p-0 hover:bg-day-light-1 ml-2"
                onClick={() => handleDeselectRoom(room.roomId)}
              >
                <RxCross2 size={20} />
              </Button>
            </div>
          ))}
        </div>
      </div>
      {Object.keys(selectedRooms).length > 0 && (
        <div className="mt-4 flex flex-col justify-center">
          <CustomSelect
            onChange={handleSelectedRoomTypeChange}
            options={roomCategoryOptions}
            placeholder={t('package-creation.ceiling-grid.select-room-type')}
            value={
              selectedRoomType
                ? roomCategoryOptions.filter((option) => option.value === selectedRoomType)
                : []
            }
            noOptionsMessage={t('custom-select.no-options')}
          />
          <Button
            primary
            rounded
            disabled={!selectedRoomType}
            className="mt-2"
            onClick={handleUpdateRoomType}
          >
            {t('package-creation.ceiling-grid.update-room-type')}
          </Button>
        </div>
      )}
    </div>
  );
};
