import { Button } from '@consigli/facade';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router';

interface NavProps {
  handleClick?: () => void;
  decorators?: Array<ReactNode>;
  isProfilePage?: boolean;
  projectId?: number;
}

export const Nav = (props: NavProps) => {
  const navigate = useNavigate();
  const { handleClick, decorators, isProfilePage } = props;
  const { t } = useTranslation();
  const defaultHandleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const actualHandleClick = useMemo(
    () => handleClick || defaultHandleClick,
    [handleClick, defaultHandleClick],
  );
  return (
    <nav className="flex w-full bg-day-light-4">
      <div className="flex grow border-b-2 border-day-light-2 px-8">
        <div className="flex items-center w-full">
          <div className="flex w-full bg-day-light-4">
            <Button
              tertiary
              rounded
              className="text-base m-3 font-semibold"
              icon={HiOutlineArrowLeft}
              onClick={actualHandleClick}
            >
              {isProfilePage ? t('profile.projects') : t('findings.back')}
            </Button>
          </div>
          <div>{decorators}</div>
        </div>
      </div>
    </nav>
  );
};
