import { TextInput, Button, Position, TextArea, Checkbox } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useCurrentLanguage,
  useProject,
  useSendFindingsMailMutation,
  useUpdateBatchFindingsMutation,
  useCreateFindingCommentMutation,
  formatApiError,
} from '@consigli/hooks';
import { ActionStatus, FindingCommentType } from '@consigli/types';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useForm, FieldValues, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { HiOutlineXMark } from 'react-icons/hi2';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import { createFindingsExcelExport } from '@/util/excel-export';
import { CheckableFinding } from '@/util/types';

import { useFindingsContext } from './findings-context';

type RecipientCardProps = {
  email: string;
  setRecipients: Dispatch<SetStateAction<string[]>>;
  setEmailInput: Dispatch<SetStateAction<string>>;
  setEmailInputError: Dispatch<SetStateAction<boolean>>;
};

const RecipientCard: FC<RecipientCardProps> = ({
  email,
  setRecipients,
  setEmailInput,
  setEmailInputError,
}) => {
  const handleButtonClick = () => {
    setRecipients((recipients) => recipients.filter((recipient) => recipient !== email));
    setEmailInput('');
    setEmailInputError(false);
  };
  return (
    <div className="max-w-fit rounded-sm flex bg-day-light-1 py-[2px] px-2">
      <p className="text-day-neutral-dark text-sm font-bold mr-1">{email}</p>
      <Button tertiary icon={HiOutlineXMark} className="p-0" onClick={handleButtonClick} />
    </div>
  );
};

type ProcessingPopupProps = {
  findings: CheckableFinding[];
  count: number;
  itemsOnPage: number;
  onClose: () => void;
  onComplete?: () => void;
};

export const ProcessingPopup: FC<ProcessingPopupProps> = ({
  findings,
  count,
  itemsOnPage,
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const currentLanguage = useCurrentLanguage();

  const [emailInput, setEmailInput] = useState('');
  const [emailInputError, setEmailInputError] = useState(false);
  const [recipients, setRecipients] = useState<string[]>([]);

  const { project } = useProject(projectId);
  const { page, setPage } = useFindingsContext();
  const [sendMail] = useSendFindingsMailMutation();
  const [updateBatchFindings, updateBatchFindingsResult] = useUpdateBatchFindingsMutation();
  const [createFindingComment] = useCreateFindingCommentMutation();
  const { register, handleSubmit, watch, control } = useForm<FieldValues>({
    defaultValues: {
      message: '',
      shouldSendMail: false,
    },
  });

  const showSendMail = watch('shouldSendMail');

  const addRecipient = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailInput !== '' && emailRegex.test(email) && !recipients.includes(email)) {
      setEmailInput('');
      setEmailInputError(false);
      setRecipients([...recipients, email]);
    } else {
      setEmailInputError(true);
    }
  };

  const handleEmailKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addRecipient(emailInput);
    }
  };

  const onSubmit = async (data: FieldValues) => {
    // Send email if enabled and recipients exist
    if (data.shouldSendMail && recipients.length > 0) {
      try {
        const findingsMail = {
          message: data.message,
          recipients: { to: recipients.map((recipient) => ({ address: recipient })) },
          excelExport: createFindingsExcelExport(findings, t, project!.name),
          language: currentLanguage,
        };
        await sendMail({
          projectId,
          packageId,
          findingsMail,
        });
        toast.success(t('toast.success-sent-mail'));
      } catch (err) {
        toast.error(t('toast.error-failed-sending-mail'));
      }
    }
    try {
      if (itemsOnPage === count && page > 1) {
        setPage((prevPage) => prevPage - 1);
      }

      const findingsToUpdate = findings.map((finding: CheckableFinding) => ({
        id: finding.id,
        action: ActionStatus.WAITING,
      }));

      await updateBatchFindings({
        projectId,
        packageId,
        data: findingsToUpdate,
      });

      if (data.message || (data.shouldSendMail && recipients.length > 0)) {
        // Only create comments if there's a message or email was sent
        await Promise.all(
          findingsToUpdate.map((finding) =>
            createFindingComment({
              projectId,
              packageId,
              findingId: finding.id,
              text: data.message !== '' ? data.message : `${recipients.join(', ')}`,
              type:
                data.message !== ''
                  ? FindingCommentType.USER_COMMENT
                  : FindingCommentType.SYSTEM_COMMENT_FORWARDED,
            }),
          ),
        );
      }

      onComplete && onComplete();
      toast.success(t('toast.success-updated-finding-status-to-waiting'));
    } catch (err) {
      toast.error(
        `${t('toast.error-failed-to-update-finding_status')}: ${formatApiError(
          updateBatchFindingsResult.error,
        )}`,
      );
    }
    onClose();
  };

  return (
    <Popup
      overlayStyle={{ background: 'rgba(100, 100, 100, 0.35)' }}
      contentStyle={{
        backgroundColor: '#fbfcfd',
        borderRadius: '10px',
        width: '80%',
        maxWidth: '500px',
      }}
      modal
      open
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full p-4">
        <div className="flex flex-row justify-between mb-4">
          <h2 className="font-bold text-2xl text-day-neutral-dark">
            {t('processing-popup.title')}
          </h2>
          <Button className="p-0" tertiary icon={HiOutlineXMark} onClick={onClose} iconSize={25} />
        </div>
        <TextArea
          {...register('message')}
          placeholder={t('processing-popup.add-comment')}
          className="w-full mb-2"
        />
        <Controller
          name="shouldSendMail"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              labelPosition={Position.RIGHT}
              label={t('processing-popup.send-email')}
              id="shouldSendMail"
              className="grid-cols-1"
            />
          )}
        />
        {showSendMail && (
          <div className="mt-2">
            <div className="flex w-full mb-2">
              <div className="flex-1 mr-3">
                <TextInput
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailInput(e.target.value)
                  }
                  onKeyPress={handleEmailKeyPress}
                  value={emailInput}
                  className="bg-white h-[42px] w-full"
                  placeholder={t('processing-popup.enter-mail')}
                />
              </div>
              <Button
                primary
                rounded
                type="button"
                className="border border-1"
                onClick={() => addRecipient(emailInput)}
                icon={FiPlus}
                iconPosition={Position.RIGHT}
                iconColor="white"
              >
                {t('processing-popup.button-add')}
              </Button>
            </div>

            {emailInputError && (
              <p className="text-sm mb-2 text-error-dark">{t('processing-popup.invalid-email')}</p>
            )}
            <div className="flex flex-wrap gap-2  ">
              {recipients.map((email) => (
                <RecipientCard
                  key={email}
                  email={email}
                  setRecipients={setRecipients}
                  setEmailInput={setEmailInput}
                  setEmailInputError={setEmailInputError}
                />
              ))}
            </div>
          </div>
        )}
        <Button
          primary
          rounded
          type="submit"
          disabled={showSendMail && recipients.length === 0}
          className="w-full mt-4"
        >
          {showSendMail ? t('processing-popup.update-and-send') : t('processing-popup.update')}
        </Button>
      </form>
    </Popup>
  );
};
