import { Search, CustomSelect, SelectOption } from '@consigli/facade';
import {
  useDebouncedSearch,
  useGetBlobUniqueDocumentTypesQuery,
  usePackageId,
  useProjectId,
} from '@consigli/hooks';
import { FoldersDocumentType } from '@consigli/types';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useFileTreeContext } from '@/contexts/use-file-tree-context';

import { status } from './draggable-container';

export const DocumentFilters: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();

  const {
    activeFolderId,
    selectedDocumentTypes,
    setSelectedDocumentTypes,
    searchContent,
    setSearchContent,
    searchFileName,
    setSearchFileName,
    setPage,
    labelsReused,
  } = useFileTreeContext();
  const debouncedSearchContent = useDebouncedSearch(searchContent, 500);
  const debouncedSearchFileName = useDebouncedSearch(searchFileName, 500);

  const { data: documentTypes } = useGetBlobUniqueDocumentTypesQuery({
    projectId,
    packageId,
    searchParsedData: debouncedSearchContent.length >= 2 ? debouncedSearchContent : '',
    standard: activeFolderId,
    documentType: selectedDocumentTypes.map((option) => option.value as FoldersDocumentType),
    searchName: debouncedSearchFileName,
    status,
    labelsReused,
  });

  const documentTypeOptions =
    documentTypes?.map((documentType) => ({
      label: t('folders.document-type.' + documentType),
      value: documentType,
    })) || [];

  const handleDocumentTypeSelect = useCallback(
    (options: SelectOption[]): void => {
      if (options.length > 0) {
        setSelectedDocumentTypes(options);
      } else {
        setSelectedDocumentTypes([]);
      }
      setPage(1);
    },
    [setSelectedDocumentTypes, setPage],
  );

  const handleSearchContent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchContent(event.target.value);
      setPage(1);
    },
    [setSearchContent, setPage],
  );
  const handleSearchName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchFileName(event.target.value);
      setPage(1);
    },
    [setSearchFileName, setPage],
  );

  return (
    <div className="flex flex-1 gap-x-2">
      <div className="flex-1">
        <CustomSelect
          options={documentTypeOptions}
          onChange={handleDocumentTypeSelect}
          value={selectedDocumentTypes}
          placeholder={t('folders.filter-on-document-type')}
          noOptionsMessage={t('custom-select.no-options')}
          isClearable
          isMulti
        />
      </div>
      <div className="flex-1">
        <Search
          text={searchFileName}
          onChange={handleSearchName}
          placeholder={t('findings.search-document-names')}
          className="w-full"
        />
      </div>
      <div className="flex-1">
        <Search
          text={searchContent}
          onChange={handleSearchContent}
          placeholder={t('findings.search_content')}
          className="w-full"
        />
      </div>
    </div>
  );
};
