import { useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { CSSProperties, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDragIndicator } from 'react-icons/md';

interface MultipleSelectionDialogProps {
  children: ReactNode;
  className?: string;
  dragId: string;
}

export const MultipleSelectionDialog: FC<MultipleSelectionDialogProps> = ({
  children,
  className,
  dragId,
}) => {
  const { t } = useTranslation();
  const { setNodeRef, transform, isDragging, attributes, listeners } = useDraggable({
    id: dragId,
  });
  const style: CSSProperties = {
    transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
    position: 'absolute',
    zIndex: 40,
    left: '40%',
    top: '60%',
    cursor: isDragging ? 'grabbing' : 'grab',
  };
  if (!children) {
    return null;
  }
  return (
    <div
      ref={setNodeRef}
      style={style}
      className={clsx(
        'p-4 bg-day-light-4 text-day-neutral-dark shadow-md flex flex-col rounded',
        className,
      )}
    >
      <div className="flex items-center mb-2">
        <div
          className="cursor-grab active:cursor-grabbing"
          {...listeners}
          {...attributes}
          tabIndex={0}
          role="button"
          aria-label={t('folders.move-file')}
        >
          <MdDragIndicator size={28} color="#CBD5E1" title={t('folders.move-file')} />
        </div>
      </div>
      {children}
    </div>
  );
};
