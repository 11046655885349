import { useMemo } from 'react';
import { useGetCeilingGridsQuery } from '../api';
export const useCeilingGrids = (params) => {
    const { data: ceilingGridsResponse, isLoading, isFetching, isError, error, } = useGetCeilingGridsQuery(params);
    return useMemo(() => {
        const totalCount = ceilingGridsResponse?.count || 0;
        const ceilingGrids = ceilingGridsResponse?.results || [];
        const next = ceilingGridsResponse?.next;
        const previous = ceilingGridsResponse?.previous;
        return {
            ceilingGrids,
            totalCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [ceilingGridsResponse, isLoading, isFetching, isError, error]);
};
