import {
  Button,
  Checkbox,
  Position,
  MultipleCheckbox,
  CustomSelect,
  SelectOption,
} from '@consigli/facade';
import { useMyUser, useProjects } from '@consigli/hooks';
import { Project, ProjectRole } from '@consigli/types';
import clsx from 'clsx';
import { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircle } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';

import { translateServiceTypes } from '@/util/translate-service-types';
import { InviteType } from '@/util/types';

interface ProjectUserProps {
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  selectedProject: SelectOption | undefined;
  clearProject: () => void;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  handleProjectAccess: () => void;
  onSelect: (option: SelectOption[]) => void;
  selectedProjectList: InviteType[] | undefined;
}

export const ProjectUser = (props: ProjectUserProps) => {
  const {
    control,
    errors,
    selectedProject,
    clearProject,
    handleProjectAccess,
    watch,
    onSelect,
    selectedProjectList,
  } = props;
  const { t } = useTranslation();
  const permissions = watch('permissions');
  const projectAdminSelected = watch('projectAdminSelected');
  const projectId = watch('projectId');
  const { projects } = useProjects({ page: 'all' });
  const disabled = useMemo(
    () => !projectId || (!projectAdminSelected && !permissions?.length),
    [projectAdminSelected, permissions.length, projectId],
  );
  const serviceTypes = translateServiceTypes(t);
  const {
    user: { isOrgAdmin, projectAccess, organizationId },
  } = useMyUser();
  const accessibleProjects = useMemo(
    () =>
      projects.filter((project) =>
        projectAccess?.some((obj) => obj.id === project.id && obj.role === ProjectRole.ADMIN),
      ),
    [projects, projectAccess],
  );
  const filteredProjects = useMemo(() => {
    let availableProjects = accessibleProjects;
    if (isOrgAdmin) {
      availableProjects = projects.filter(
        (project) =>
          project.organizationId === organizationId ||
          projectAccess.some(
            (access) => access.id === project.id && access.role === ProjectRole.ADMIN,
          ),
      );
    }
    return !selectedProjectList || selectedProjectList?.length === 0
      ? availableProjects
      : availableProjects.filter((obj) =>
          selectedProjectList?.every((filterObj) => filterObj.id !== obj.id),
        );
  }, [
    isOrgAdmin,
    projects,
    organizationId,
    projectAccess,
    selectedProjectList,
    accessibleProjects,
  ]);
  const projectOptions = useMemo(
    () => filteredProjects?.map((project: Project) => ({ label: project.name, value: project.id })),
    [filteredProjects],
  );

  return (
    <div className="relative">
      <span className="absolute right-0 cursor-pointer">
        <RxCross2 fontSize={24} onClick={() => clearProject()} />
      </span>
      <div className="my-6">
        <div className="text-base font-semibold text-day-neutral-subtle block">
          {t('invite-user.project-access')}
        </div>
        <div className="flex gap-x-4 items-center justify-center">
          <CustomSelect
            onChange={(selectedOptions: SelectOption[]): void => {
              onSelect(selectedOptions);
            }}
            placeholder={t('invite-user.select-project')}
            options={projectOptions}
            value={selectedProject ? [selectedProject] : []}
            className="w-full"
            noOptionsMessage={t('custom-select.no-options')}
            isClearable
          />
        </div>
        {projectOptions.length === 0 && !selectedProjectList?.length ? (
          <div className="text-sm my-2 text-error-dark">{t('invite-user.contact-admin')}</div>
        ) : null}
      </div>
      {projectId ? (
        <>
          <Controller
            name="projectAdminSelected"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <Checkbox
                  {...field}
                  checked={field.value}
                  labelPosition={Position.RIGHT}
                  label={t('invite-user.project-admin')}
                  id="projectAdminSelected"
                  className="grid-cols-1"
                />
                <div className="flex mt-2 opacity-50">
                  <IoIosInformationCircle />
                  <p className="text-xs ml-1">
                    <b>{t('invite-user.role-project-admin')}</b>
                    {t('invite-user.role-project-admin-description')}
                  </p>
                </div>
              </>
            )}
          />
          <div
            className={clsx(
              'transition-opacity transition-height ease-in-out duration-500',
              projectAdminSelected ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100 h-auto',
            )}
          >
            {!projectAdminSelected ? (
              <>
                <div className="text-base text-day-neutral-subtle mt-4 pt-4">
                  {t('invite-user.choose-service-access')}
                </div>
                <MultipleCheckbox
                  options={serviceTypes}
                  control={control}
                  errors={errors}
                  groupName="permissions"
                  label=""
                  classNames="grid-cols-2 text-xs"
                  selectAllLabel={t('findings.select-all')}
                  id="invitePermissions"
                />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <></>
      )}

      <Button
        rounded
        primary
        className="w-full mt-4"
        disabled={disabled}
        onClick={handleProjectAccess}
      >
        {t('invite-user.add-project-access')}
      </Button>
    </div>
  );
};
