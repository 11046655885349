import { Spinner } from '@consigli/facade';
import {
  useProjectId,
  useStructuralId,
  useServiceName,
  useGetStructuralEngineeringResultsQuery,
} from '@consigli/hooks';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { Route } from '@/routes';

// This component is used to navigate to the correct structural page when switching packages
// If the structural has results, it will navigate to the results page
// If not, it will navigate to the documents page
export const StructuralNavigationWrapper = () => {
  const projectId = useProjectId();
  const structuralId = useStructuralId();
  const serviceName = useServiceName();
  const navigate = useNavigate();

  const { data: structuralResults } = useGetStructuralEngineeringResultsQuery({
    projectId,
    structuralId,
    page: 'all',
  });

  const hasResults = useMemo(() => {
    return structuralResults ? structuralResults.results.length > 0 : undefined;
  }, [structuralResults]);

  const navRoute = useMemo(() => {
    return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${structuralId}/${hasResults ? Route.RESULTS : Route.INPUT}`;
  }, [projectId, serviceName, structuralId, hasResults]);

  useEffect(() => {
    if (hasResults !== undefined) {
      navigate(navRoute, { replace: true });
    }
  }, [hasResults, navRoute, navigate]);

  return <Spinner size="large" />;
};
