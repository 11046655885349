import { LoadingArea } from '@consigli/facade';
import { useReadUrl } from '@consigli/hooks';
import { Document } from '@consigli/types';
import { FC } from 'react';

import { ImageViewer } from './image-viewer';

interface ImageContainerProps {
  document: Document;
  isPreview?: boolean;
}

export const ImageContainer: FC<ImageContainerProps> = ({ document, isPreview }) => {
  const { readUrl, isLoading } = useReadUrl(document);
  return (
    <div className={isPreview ? 'w-11/12 mb-1' : 'w-5/6 mb-6'}>
      <p className="text-white text-center">{document.name}</p>
      {isLoading ? (
        <LoadingArea />
      ) : (
        readUrl && (
          <div className={`border border-day-primary w-full ${isPreview ? 'h-fit' : 'h-full'}`}>
            <ImageViewer readUrl={readUrl} fileName={document.name} />
          </div>
        )
      )}
    </div>
  );
};
