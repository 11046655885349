import {
  useProjectId,
  usePackageId,
  useServiceName,
  usePackageByServiceIdAndPackageId,
} from '@consigli/hooks';
import { ServiceId } from '@consigli/types';
import { type FC } from 'react';
import { Outlet } from 'react-router';

import { DocumentListProvider } from '@/contexts/document-list-context';
import { Breadcrumb } from '@/layouts/breadcrumb/breadcrumb';
import { Route, ServiceIdIndexValue } from '@/routes';

export const InsightDetailWrapper: FC = () => {
  const projectId = useProjectId();
  const packageId = usePackageId();
  const serviceName = useServiceName();

  const { pkg } = usePackageByServiceIdAndPackageId(
    projectId,
    ServiceIdIndexValue[serviceName] as ServiceId,
    packageId,
  );

  return (
    <>
      <Breadcrumb
        label={pkg?.name ?? ''}
        to={`/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${packageId}/${Route.DASHBOARD}`}
      />
      <DocumentListProvider>
        <Outlet />
      </DocumentListProvider>
    </>
  );
};
