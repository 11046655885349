import {
  useGetLayoutOptimizationsQuery,
  useGetPackagesByServiceIdQuery,
  useGetStructuralEngineeringsQuery,
} from '@consigli/hooks';
import { ServiceId } from '@consigli/types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OptimizationType,
  PackageType,
  StructuralEngineeringType,
} from '@/features/package-list/package-list';

type FetchHookParams =
  | { projectId: number; serviceId: ServiceId } // For useGetPackagesByServiceIdQuery
  | { projectId: number };
export const useValidateName = (
  fetchHook:
    | typeof useGetPackagesByServiceIdQuery
    | typeof useGetStructuralEngineeringsQuery
    | typeof useGetLayoutOptimizationsQuery,
  params: FetchHookParams,
) => {
  const { t } = useTranslation();
  const isPackagesQuery = (hook: typeof fetchHook): hook is typeof useGetPackagesByServiceIdQuery =>
    hook === useGetPackagesByServiceIdQuery;

  const isStructuralQuery = (
    hook: typeof fetchHook,
  ): hook is typeof useGetStructuralEngineeringsQuery => hook === useGetStructuralEngineeringsQuery;

  const isLayoutQuery = (hook: typeof fetchHook): hook is typeof useGetLayoutOptimizationsQuery =>
    hook === useGetLayoutOptimizationsQuery;

  let data: PackageType[] | OptimizationType[] | StructuralEngineeringType[] = useMemo(
    () => [],
    [],
  );
  if (isPackagesQuery(fetchHook)) {
    const { data: packagesData } = fetchHook(params as { projectId: number; serviceId: ServiceId });
    data = packagesData || [];
  } else if (isStructuralQuery(fetchHook)) {
    const { data: structuralData } = fetchHook(params as { projectId: number });
    data = structuralData || [];
  } else if (isLayoutQuery(fetchHook)) {
    const { data: layoutData } = fetchHook(params as { projectId: number });
    data = layoutData || [];
  }
  const validatePackageName = useCallback(
    (value: string) => {
      if (data.find((pkg) => pkg.name === value)) return t('package-creation.package-name-taken');
      return Boolean(value && !data.find((pkg) => pkg.name === value));
    },
    [t, data],
  );
  return validatePackageName;
};
