import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Breadcrumb } from '@/layouts/breadcrumb/breadcrumb';
import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { Nav } from '@/layouts/nav';

import { UserProfilePage } from './user-profile-page';

export const UserProfileWrapper: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb label={t('navbar.breadcrumbs.myprofile')} to={`/profile`} />
      <FluidLayout>
        <CenteredLayout>
          <Nav handleClick={() => navigate('/projects')} isProfilePage={true} />
          <UserProfilePage />
        </CenteredLayout>
      </FluidLayout>
    </>
  );
};
