import { useToggle } from '@consigli/hooks';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { FaIndent, FaOutdent } from 'react-icons/fa';

interface WorkspaceSidebarProps {
  children?: ReactNode | undefined;
  sidebarExpanded?: boolean;
}

export const WorkspaceSidebar: FC<WorkspaceSidebarProps> = ({
  children,
  sidebarExpanded = true,
}) => {
  const { expanded, toggle } = useToggle(sidebarExpanded);

  return (
    <div>
      <div
        className={clsx(
          'shadow h-[calc(100vh-13rem)] relative transition-all duration-1000 ease-in-out bg-day-light-4 z-[1]',
          expanded ? 'w-[16rem] md:w-[20rem]' : 'w-0',
        )}
      >
        <div
          className="absolute w-8 h-8 mt-4 bg-day-light-4 -right-[32px] shadow rounded-r-md flex items-center justify-center cursor-pointer"
          onClick={toggle}
          aria-hidden
        >
          {expanded ? <FaOutdent></FaOutdent> : <FaIndent></FaIndent>}
        </div>
        <div
          className={clsx(
            'transition-all duration-1000 ease-in-out h-full overflow-y-auto overflow-x-hidden',
            expanded ? 'w-[16rem] md:w-[20rem]' : 'w-0',
          )}
        >
          <div className="p-4 w-[calc(16rem-.5rem)] md:w-[calc(20rem-.5rem)]">{children}</div>
        </div>
      </div>
    </div>
  );
};
