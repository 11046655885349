import { FC } from 'react';
import { Link } from 'react-router';

import ConsigliLogoShort from '@/assets/images/consigli-logo-black-text-transparent-short.png';
import ConsigliLogoBlack from '@/assets/images/consigli-logo-black.svg';
import { CustomerSupportButton } from '@/layouts/page-header/customer-support-button';
import { TranslationMenuButton } from '@/layouts/page-header/translation-menu-button';
import { UserMenuButton } from '@/layouts/page-header/user-menu-button';
import { Route } from '@/routes';

export const PageHeader: FC = () => {
  return (
    <nav
      className="flex flex-row w-full h-14 z-[60] bg-day-light-5 sticky top-0 left-0 justify-between items-center"
      role="navigation"
      aria-label="main navigation"
    >
      <span className="sm:px-6">
        <Link
          to={`../${Route.PROJECTS}`}
          className="flex flex-row items-center justify-center w-[80px] min-w-[80px] sm:w-[220px] sm:min-w-[220px] h-full py-3.5 focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-day-primary"
        >
          <img
            src={ConsigliLogoShort}
            alt="Consigli Logo"
            className="block sm:hidden mb-0 h-full"
          />
          <img
            data-testid="consigli-logo"
            src={ConsigliLogoBlack}
            alt="Consigli Logo"
            className="hidden sm:block h-full"
          />
        </Link>
      </span>
      <div className="flex grow justify-end flex-row items-center px-4">
        <div className="flex flex-row items-center">
          <CustomerSupportButton />
          <UserMenuButton />
          <TranslationMenuButton />
        </div>
      </div>
    </nav>
  );
};
