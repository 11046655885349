import {
  useCreateLayoutOptimizationMutation,
  useCreateStructuralEngineeringMutation,
  useFileUpload,
  useProjectId,
  useServiceName,
  useStartPipelineMutation,
  useCreateCeilingGridMutation,
} from '@consigli/hooks';
import {
  LayoutType,
  PipelineName,
  StructuralType,
  UploadFile,
  PackageType,
  CeilingType,
} from '@consigli/types';
import { zip } from '@consigli/utils';
import limit from 'p-limit';
import { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { Route } from '@/routes';

import { PackageCreationHook } from './use-create-insight-package';

interface StructuralPayload {
  type: StructuralType;
  name: string;
  fileIds: string[];
  data: {
    comments: string;
  };
}

interface LayoutPayload {
  type: LayoutType;
  name: string;
  file_ids: string[];
  data: {
    comments: string;
    ceilingHeight: unknown;
  };
}

interface CeilingPayload {
  name: string;
  file_ids: string[];
}

type Payload = StructuralPayload | LayoutPayload | CeilingPayload;

export const useCreatePackageUtil = ({
  setCurrentCounter,
  setTargetCounter,
}: PackageCreationHook) => {
  const { upload } = useFileUpload();
  const projectId = useProjectId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const [comments] = useState('');
  const [createStructuralEngineering] = useCreateStructuralEngineeringMutation();
  const [createLayoutOptimization] = useCreateLayoutOptimizationMutation();
  const [createCeilingGrid] = useCreateCeilingGridMutation();
  const [startPipeline] = useStartPipelineMutation();

  const uploadWithCounter = useCallback(
    async (file: File) => {
      let blob;
      try {
        blob = await upload(file, projectId);
      } finally {
        setCurrentCounter((counter) => counter + 1);
        return blob;
      }
    },
    [projectId, upload, setCurrentCounter],
  );

  const uploadFiles = useCallback(
    async (files: File[]): Promise<UploadFile[]> => {
      setCurrentCounter(0);
      setTargetCounter(files.length);

      const queue = limit(100);
      const input = files.map((file) => queue(uploadWithCounter, file));

      const results = await Promise.allSettled(input);

      const uploadFiles = files.map((file) => file as UploadFile);

      return zip(uploadFiles, results).map(([file, promise]) => {
        if (promise.status === 'fulfilled' && promise.value) {
          file.id = promise.value.id;
        }
        return file;
      });
    },
    [uploadWithCounter, setTargetCounter, setCurrentCounter],
  );

  const onAnalyze = useCallback(
    async (
      localFiles: File[],
      formData: FieldValues,
      type: StructuralType | LayoutType | CeilingType,
    ) => {
      try {
        const files = await uploadFiles(localFiles);
        const fileIds = files.map((file) => file.id).filter((id): id is string => id !== undefined);
        let payload: Payload;
        let response;
        if (type === StructuralType.STRUCTURAL) {
          payload = {
            name: formData['iterationName'],
            type,
            fileIds, // Use fileIds for StructuralType
            data: { comments },
          };
          response = await createStructuralEngineering({
            projectId,
            payload,
          }).unwrap();
        } else if (type === CeilingType.CEILING_GRID) {
          response = await createCeilingGrid({
            projectId,
            payload: {
              name: formData['iterationName'],
              file_ids: fileIds,
            },
          }).unwrap();
        } else {
          payload = {
            name: formData['iterationName'],
            type,
            file_ids: fileIds, // Use file_ids for LayoutType
            data: {
              comments: comments,
              ceilingHeight: formData['ceilingHeight'],
            },
          };
          response = await createLayoutOptimization({
            projectId,
            payload,
          }).unwrap();
        }

        if (response) toast.success(t('package-creation.success-analyzed'));
        if (type !== CeilingType.CEILING_GRID) {
          navigate(
            `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${response.id}/${Route.DASHBOARD}`,
          );
        }
        return response;
      } catch (error) {
        toast.error(t(`package-creation.failed-analyzed`));
      }
    },
    [
      navigate,
      projectId,
      serviceName,
      t,
      uploadFiles,
      comments,
      createStructuralEngineering,
      createLayoutOptimization,
      createCeilingGrid,
    ],
  );

  const startAnalysis = useCallback(
    async (packageId: string, pipelineName: PipelineName) => {
      await startPipeline({
        projectId,
        packageId,
        pipelineName,
        notify: true,
        packageType: PackageType.CEILING_GRID,
      });
    },
    [startPipeline, projectId],
  );

  return {
    uploadFiles,
    onAnalyze,
    startAnalysis,
  };
};
