import { Button } from '@consigli/facade';
import { FC, useCallback } from 'react';
import { BiTargetLock } from 'react-icons/bi';
import { PiArrowUpBold, PiArrowLeftBold, PiArrowDownBold, PiArrowRightBold } from 'react-icons/pi';

import { useCeilingViewerContext } from '../ceiling-viewer-context';

export const ControlPanel: FC = () => {
  const { setViewBox, initialViewBox } = useCeilingViewerContext();

  const handleResetView = useCallback(() => {
    setViewBox(initialViewBox);
  }, [setViewBox, initialViewBox]);

  const moveView = useCallback(
    (dx: number, dy: number) => {
      setViewBox((prev) => ({
        ...prev,
        x: prev.x + dx,
        y: prev.y + dy,
      }));
    },
    [setViewBox],
  );

  const handleMoveUp = useCallback(() => moveView(0, 3), [moveView]);
  const handleMoveDown = useCallback(() => moveView(0, -3), [moveView]);
  const handleMoveLeft = useCallback(() => moveView(-3, 0), [moveView]);
  const handleMoveRight = useCallback(() => moveView(3, 0), [moveView]);

  return (
    <div className="absolute top-4 right-4 bg-transparent p-2 rounded flex flex-col items-center">
      <Button
        onClick={handleMoveUp}
        tertiary
        rounded
        className="bg-day-light-1-300 hover:bg-day-dark-1 focus:bg-day-light-1-300 p-2"
        icon={PiArrowUpBold}
        iconSize={30}
      />
      <div className="flex">
        <Button
          onClick={handleMoveLeft}
          tertiary
          rounded
          className="bg-day-light-1-300 hover:bg-day-dark-1 focus:bg-day-light-1-300 p-2 my-1"
          icon={PiArrowLeftBold}
          iconSize={30}
        />
        <Button
          onClick={handleResetView}
          tertiary
          rounded
          className="bg-day-light-1-300 hover:bg-day-dark-1 focus:bg-day-light-1-300 p-2 m-1"
          icon={BiTargetLock}
          iconSize={30}
        />
        <Button
          onClick={handleMoveRight}
          tertiary
          rounded
          className="bg-day-light-1-300 hover:bg-day-dark-1 focus:bg-day-light-1-300 p-2 my-1"
          icon={PiArrowRightBold}
          iconSize={30}
        />
      </div>
      <Button
        onClick={handleMoveDown}
        tertiary
        rounded
        className="bg-day-light-1-300 hover:bg-day-dark-1 focus:bg-day-light-1-300 p-2"
        icon={PiArrowDownBold}
        iconSize={30}
      />
    </div>
  );
};
