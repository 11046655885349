import { CustomSelect, SelectOption } from '@consigli/facade';
import { DocumentPackage } from '@consigli/types';
import { useCallback, useMemo } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCompareContext } from '../compare/use-compare-packages';

export const ExistingPackage = () => {
  const { t } = useTranslation();
  const { resetField } = useFormContext<FieldValues>();

  const {
    setSecondaryData,
    selectedComparisonPackage,
    setSelectedComparisonPackage,
    setSecondaryFiles,
    comparePackages: packages,
  } = useCompareContext();
  const options: SelectOption[] = useMemo(() => {
    return (
      packages?.map((spec) => ({
        label: spec.name,
        value: spec.id,
      })) || []
    );
  }, [packages]);

  const handleSelectChange = useCallback(
    (selectedOptions: SelectOption[]): void => {
      if (selectedOptions.length > 0) {
        const option = selectedOptions[0];
        const pkg = packages?.find((spec) => spec.id === option.value);
        setSelectedComparisonPackage(pkg as DocumentPackage);
        setSecondaryFiles([]);
        setSecondaryData({ secondaryPackageName: '' });
        resetField('secondaryPackageName');
      } else {
        setSelectedComparisonPackage(undefined);
      }
    },
    [setSelectedComparisonPackage, packages, setSecondaryFiles, setSecondaryData, resetField],
  );
  return (
    <>
      {options.length !== 0 ? (
        <>
          <div className="text-base font-semibold mt-4 mb-2 text-day-neutral-subtle">
            {t('package-creation.compare-with-existing-package')}
          </div>
          <CustomSelect
            options={options}
            onChange={handleSelectChange}
            value={
              selectedComparisonPackage
                ? [
                    {
                      label: selectedComparisonPackage.name,
                      value: selectedComparisonPackage.id,
                    },
                  ]
                : []
            }
            placeholder={t('package-creation.select-package')}
            isClearable
            noOptionsMessage={t('custom-select.no-options')}
            isMulti={false}
          />
        </>
      ) : (
        <div className="text-base font-semibold text-day-neutral-subtle">
          {t('package-creation.no-packages-found-create-new')}
        </div>
      )}
    </>
  );
};
