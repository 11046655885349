import { Button } from '@consigli/facade';
import { LocalizationContext, LocalizationMap } from '@react-pdf-viewer/core';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import {
  RenderGoToPageProps,
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import { RenderShowSearchPopoverProps, searchPlugin } from '@react-pdf-viewer/search';
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
  zoomPlugin,
} from '@react-pdf-viewer/zoom';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { GrSearch } from 'react-icons/gr';
import { HiOutlineCloudDownload } from 'react-icons/hi';
import { HiOutlineMagnifyingGlassMinus, HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

import en from '@/components/pdf-viewer/locales/en/translation.json';
import ja from '@/components/pdf-viewer/locales/ja/translation.json';
import nb from '@/components/pdf-viewer/locales/nb/translation.json';
import nn from '@/components/pdf-viewer/locales/nn/translation.json';
import sv from '@/components/pdf-viewer/locales/sv/translation.json';
import vi from '@/components/pdf-viewer/locales/vi/translation.json';
import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';

export const usePDFToolbar = (documentName: string) => {
  const navigation = pageNavigationPlugin();
  const zoom = zoomPlugin();
  const search = searchPlugin();
  const { setMode } = useViewerContext();
  const download = getFilePlugin({
    fileNameGenerator: () => documentName,
  });

  const { GoToPreviousPage, GoToNextPage, CurrentPageLabel } = navigation;
  const { ZoomIn, ZoomOut, CurrentScale } = zoom;
  const { Download } = download;
  const { ShowSearchPopover } = search;

  const [l10n, setL10n] = useState(nb as unknown as LocalizationMap);

  const chooseLanguage = () => {
    const currentLanguage = i18next.language;
    switch (currentLanguage) {
      case 'en':
        return setL10n(en as unknown as LocalizationMap);
      case 'sv':
        return setL10n(sv as unknown as LocalizationMap);
      case 'vi':
        return setL10n(vi as unknown as LocalizationMap);
      case 'ja':
        return setL10n(ja as unknown as LocalizationMap);
      case 'nn':
        return setL10n(nn as unknown as LocalizationMap);
      default:
        return setL10n(nb as unknown as LocalizationMap);
    }
  };

  useEffect(() => {
    chooseLanguage();
  }, []);

  const fullscreenToolbar = useMemo(() => {
    const localizationContext = { l10n, setL10n };
    return (
      <>
        <div className="w-full h-4 bg-gradient-to-b from-[#EDF2F7] to-[#F9FBFC] flex items-center justify-center"></div>
        <div className="w-full h-16 bg-day-light-4 flex items-center justify-center">
          <LocalizationContext.Provider value={localizationContext}>
            <div className="p-1.5 bg-day-light-4 rounded-md">
              <div className="flex flex-row items-center gap-8 layout">
                <ShowSearchPopover>
                  {(props: RenderShowSearchPopoverProps) => (
                    <>
                      <Button
                        tertiary
                        onClick={props.onClick}
                        className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                      >
                        <GrSearch size={24} />
                      </Button>
                    </>
                  )}
                </ShowSearchPopover>
                <div className="flex items-center gap-0.5">
                  <GoToPreviousPage>
                    {(props: RenderGoToPageProps) => (
                      <Button
                        tertiary
                        onClick={props.onClick}
                        className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                      >
                        <IoChevronUpOutline size={24} />
                      </Button>
                    )}
                  </GoToPreviousPage>
                  <CurrentPageLabel>
                    {(props: RenderCurrentPageLabelProps) => (
                      <span className="text-[#9CA4AE] inline-block min-w-[20px] text-xl">
                        {props.numberOfPages
                          ? `${props.currentPage + 1}/${props.numberOfPages}`
                          : '...'}
                      </span>
                    )}
                  </CurrentPageLabel>
                  <GoToNextPage>
                    {(props: RenderGoToPageProps) => (
                      <Button
                        tertiary
                        onClick={props.onClick}
                        className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                      >
                        <IoChevronDownOutline size={24} />
                      </Button>
                    )}
                  </GoToNextPage>
                </div>
                <div className="flex items-center gap-0.5">
                  <ZoomIn>
                    {(props: RenderZoomInProps) => (
                      <Button
                        tertiary
                        onClick={props.onClick}
                        className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                      >
                        <HiOutlineMagnifyingGlassPlus size={24} />
                      </Button>
                    )}
                  </ZoomIn>
                  <CurrentScale>
                    {(props: RenderCurrentScaleProps) => (
                      <span className="text-[#9CA4AE]">{`${Math.round(props.scale * 100)}%`}</span>
                    )}
                  </CurrentScale>

                  <ZoomOut>
                    {(props: RenderZoomOutProps) => (
                      <Button
                        tertiary
                        onClick={props.onClick}
                        className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                      >
                        <HiOutlineMagnifyingGlassMinus size={24} />
                      </Button>
                    )}
                  </ZoomOut>
                </div>
              </div>
            </div>
          </LocalizationContext.Provider>
        </div>
      </>
    );
  }, [
    CurrentPageLabel,
    CurrentScale,
    GoToNextPage,
    GoToPreviousPage,
    ShowSearchPopover,
    ZoomIn,
    ZoomOut,
    l10n,
  ]);

  const previewToolbar = useMemo(() => {
    const localizationContext = { l10n, setL10n };

    return (
      <LocalizationContext.Provider value={localizationContext}>
        <div className="flex items-center p-1.5 bg-day-light-4 rounded-md py-6 justify-between px-10">
          <div>
            <div className="flex flex-row items-center gap-8 layout">
              <ShowSearchPopover>
                {(props: RenderShowSearchPopoverProps) => (
                  <>
                    <Button
                      tertiary
                      onClick={props.onClick}
                      className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                    >
                      <GrSearch size={24} />
                    </Button>
                  </>
                )}
              </ShowSearchPopover>
              <div className="flex items-center gap-0.5">
                <GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <Button
                      tertiary
                      onClick={props.onClick}
                      className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                    >
                      <IoChevronUpOutline size={24} />
                    </Button>
                  )}
                </GoToPreviousPage>
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <span className="text-[#9CA4AE] inline-block min-w-[20px] text-xl">
                      {props.numberOfPages
                        ? `${props.currentPage + 1}/${props.numberOfPages}`
                        : '...'}
                    </span>
                  )}
                </CurrentPageLabel>
                <GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <Button
                      tertiary
                      onClick={props.onClick}
                      className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                    >
                      <IoChevronDownOutline size={24} />
                    </Button>
                  )}
                </GoToNextPage>
              </div>
              <div className="flex items-center gap-0.5">
                <ZoomIn>
                  {(props: RenderZoomInProps) => (
                    <Button
                      tertiary
                      onClick={props.onClick}
                      className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                    >
                      <HiOutlineMagnifyingGlassPlus size={24} />
                    </Button>
                  )}
                </ZoomIn>
                <CurrentScale>
                  {(props: RenderCurrentScaleProps) => (
                    <span className="text-[#9CA4AE]">{`${Math.round(props.scale * 100)}%`}</span>
                  )}
                </CurrentScale>

                <ZoomOut>
                  {(props: RenderZoomOutProps) => (
                    <Button
                      tertiary
                      onClick={props.onClick}
                      className="p-2 hover:bg-day-light-3 rounded-full text-[#9CA4AE] text-xl"
                    >
                      <HiOutlineMagnifyingGlassMinus size={24} />
                    </Button>
                  )}
                </ZoomOut>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Download>
              {(props: RenderDownloadProps) => (
                <Button
                  tertiary
                  className="p-2 hover:bg-day-light-3 rounded-full text-xl text-[#9CA4AE]"
                  onClick={props.onClick}
                >
                  <HiOutlineCloudDownload />
                </Button>
              )}
            </Download>
            <Button
              tertiary
              className="p-2 hover:bg-day-light-3 rounded-full text-xl text-[#9CA4AE]"
              onClick={() => setMode(ViewerMode.DocumentFullscreen)}
            >
              <AiOutlineFullscreen />
            </Button>
          </div>
        </div>
      </LocalizationContext.Provider>
    );
  }, [
    CurrentPageLabel,
    CurrentScale,
    GoToNextPage,
    GoToPreviousPage,
    ShowSearchPopover,
    ZoomIn,
    ZoomOut,
    l10n,
    Download,
    setMode,
  ]);

  return {
    fullscreenToolbar,
    previewToolbar,
    navigation,
    zoom,
    download,
    search,
  };
};
