import {
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@consigli/facade';
import { useMyUser, useUsers } from '@consigli/hooks';
import { ProjectRole } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';

import { UserManagementProjectAccessCard } from './user-management-project-access-card';

export const UserManagementUserList: FC = () => {
  const { t } = useTranslation();
  const { user } = useMyUser();
  const pageSize = 8;
  const [page, setPage] = useState(1);

  const { users, paginatedCount, isFetching } = useUsers({ page: page, pageSize: pageSize });

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  return (
    <>
      <div className="max-h-[60vh] overflow-y-scroll">
        <Table>
          <TableHead className="sticky top-0 bg-day-light-4">
            <TableRow>
              <TableHeaderCell className="pr-16 text-sm max-w-[25vw]">
                {t('user-management.name')}
              </TableHeaderCell>
              <TableHeaderCell className="pr-16 text-sm max-w-[25vw]">
                {t('user-management.email')}
              </TableHeaderCell>
              <TableHeaderCell className="text-sm max-w-[50vw]">
                {t('user-management.project-access')}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableDataCell className="text-center py-8">
                  <div className="flex justify-center items-center w-full">
                    <Spinner />
                  </div>
                </TableDataCell>
              </TableRow>
            ) : (
              users.map((editableUser) => (
                <TableRow key={editableUser.id}>
                  <TableDataCell className="pr-16 text-sm max-w-[25vw] min-h-10 truncate">
                    {editableUser.firstName} {editableUser.lastName}
                  </TableDataCell>
                  <TableDataCell className="pr-16 text-sm max-w-[25vw] min-h-10 truncate">
                    {editableUser.email}
                  </TableDataCell>
                  <TableDataCell className="flex flex-wrap max-w-[50vw] min-h-10">
                    {/* Superusers can see all org admins, org admins can only see org admins from the same org */}
                    {editableUser.isOrgAdmin &&
                      (user.isSuperuser ||
                        (user.isOrgAdmin &&
                          user.organizationId === editableUser.organizationId)) && (
                        <UserManagementProjectAccessCard
                          key={editableUser.id}
                          userId={editableUser.id}
                          userName={editableUser.firstName + ' ' + editableUser.lastName}
                          name={t('user-management.all-projects')}
                          role={ProjectRole.ADMIN}
                        />
                      )}
                    {editableUser.projectAccess.map((access) => (
                      <UserManagementProjectAccessCard
                        key={access.id}
                        userId={editableUser.id}
                        userName={editableUser.firstName + ' ' + editableUser.lastName}
                        projectId={access.id}
                        projectName={access.name}
                        name={access.name}
                        role={access.role}
                      />
                    ))}
                  </TableDataCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-6 flex justify-between w-full items-center">
        <div className="flex items-center">
          <p className="font-bold mr-4 text-xs">
            {users.length} {t('user-management.title')}
          </p>
          <Pagination
            currentPage={page}
            totalCount={paginatedCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="flex">
          <div className="flex mr-4 items-center">
            <div className="bg-night-light-3 h-5 w-6 rounded flex justify-center items-center mr-1">
              <IoShieldCheckmarkOutline
                size={16}
                title="ProjectAdminIcon"
                className="text-night-neutral-dark"
              />
            </div>
            <p className="font-bold text-xs">{t('user-management.project-admin')}</p>
          </div>
          <div className="flex items-center">
            <div className="bg-day-light-2 h-5 w-6 rounded mr-1" />
            <p className="font-bold text-xs">{t('user-management.project-user')}</p>
          </div>
        </div>
      </div>
    </>
  );
};
