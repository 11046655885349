import { LoadingArea } from '@consigli/facade';
import { Document, DocumentType } from '@consigli/types';
import clsx from 'clsx';
import { FC } from 'react';

import { useDocumentsContext } from '@/contexts/document-list-context';

import { PDFViewer } from './pdf-viewer';

interface SinglePdfViewProps {
  document: Document;
  isPreview: boolean;
}

export const SinglePdfViewer: FC<SinglePdfViewProps> = ({ document, isPreview }) => {
  const { searchTerm } = useDocumentsContext();
  if (document.fileUrl === undefined) {
    return <LoadingArea />;
  }

  // For other document types (BLOB, FILE, PARSED_DATA), searchTerm is passed through
  if (
    (document.type === DocumentType.BLOB && document.fileUrl) ||
    (document.type === DocumentType.FILE && document.fileUrl)
  ) {
    return (
      <div
        className={clsx(
          isPreview
            ? 'w-11/12 mb-1 shadow-[0px_0px_12px_0px_rgba(0,0,0,0.25)] h-[50vh] 2xl:h-[61vh] bg-white'
            : 'w-5/6 mb-6',
        )}
      >
        <PDFViewer
          fileUrl={document.fileUrl}
          documentName={document.name}
          isPreview={isPreview}
          searchTerm={searchTerm}
        />
      </div>
    );
  }
};
