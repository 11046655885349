import { Button, UseStepContext, Position, Spinner } from '@consigli/facade';
import { useLazyGetCeilingGridBuildingJsonQuery, useProjectId } from '@consigli/hooks';
import { PipelineName } from '@consigli/types';
import { mapToCamelCase } from '@consigli/utils';
import clsx from 'clsx';
import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { useCeilingViewerContext } from '@/features/ceiling-viewer/ceiling-viewer-context';

import { CeilingGridResponse } from './ceiling-wizard';

interface CheckIFCButtonProps {
  localFiles: File[];
  onCeilingGridCreation: () => CeilingGridResponse;
  startAnalysis: (packageId: string, pipelineName: PipelineName) => Promise<void>;
  context: UseStepContext;
  type?: 'button' | 'submit' | undefined;
}

export const CheckIFCButton: FC<CheckIFCButtonProps> = ({
  localFiles,
  onCeilingGridCreation,
  startAnalysis,
  context,
  type,
}) => {
  const { t } = useTranslation();
  const { next } = context();
  const projectId = useProjectId();
  const { trigger } = useFormContext();
  const {
    createdCeilingGridId,
    setCreatedCeilingGridId,
    isIFCApproved,
    setIsIFCApproved,
    isProcessingIFC,
    setIsProcessingIFC,
    setCompleteCeilingJSON,
    setAllFloors,
  } = useCeilingViewerContext();

  const [getCeilingGridBuildingJson] = useLazyGetCeilingGridBuildingJsonQuery();

  useEffect(() => {
    // This useEffect checks every 5 seconds if the building json is added to the ceiling grid.
    // Once its added we can go to the next step of selecting floors.
    const interval = setInterval(async () => {
      if (createdCeilingGridId) {
        const ceilingGridBuildingJson = await getCeilingGridBuildingJson({
          projectId,
          ceilingGridId: createdCeilingGridId,
        }).unwrap();
        if (ceilingGridBuildingJson) {
          const jsonString =
            typeof ceilingGridBuildingJson === 'string'
              ? ceilingGridBuildingJson
              : JSON.stringify(ceilingGridBuildingJson);
          const data = JSON.parse(jsonString);
          setCompleteCeilingJSON(mapToCamelCase(data));
          setAllFloors(mapToCamelCase(data.floors));
          setIsIFCApproved(true);
          setIsProcessingIFC(false);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [
    createdCeilingGridId,
    projectId,
    setIsIFCApproved,
    setIsProcessingIFC,
    setCompleteCeilingJSON,
    setAllFloors,
    getCeilingGridBuildingJson,
  ]);

  const handleButtonClick = useCallback(async () => {
    if (!isIFCApproved) {
      const iterationNameExists = await trigger('iterationName');
      if (!iterationNameExists) {
        return;
      }
      setIsProcessingIFC(true);
      try {
        const ceilingGridResponse = await onCeilingGridCreation();
        if (ceilingGridResponse) {
          setCreatedCeilingGridId(ceilingGridResponse.id);
          await startAnalysis(ceilingGridResponse.id, PipelineName.IFC_EXTRACTION_ONLY);
          toast.success(t('package-creation.analyze-started'));
        } else {
          toast.error(t('package-creation.failed-analyzed'));
        }
      } catch (error) {
        toast.error(t('package-creation.failed-analyzed'));
      }
    } else {
      next();
    }
  }, [
    isIFCApproved,
    trigger,
    setIsProcessingIFC,
    t,
    onCeilingGridCreation,
    setCreatedCeilingGridId,
    startAnalysis,
    next,
  ]);

  return (
    <>
      <Button
        rounded
        primary
        className={clsx('w-full fc-px-3 fc-py-2 mt-2', {
          'text-sm': localFiles.length > 999,
        })}
        onClick={handleButtonClick}
        disabled={isProcessingIFC || localFiles?.length === 0}
        type={type || 'button'}
        icon={isIFCApproved ? BsArrowRight : undefined}
        iconPosition={isIFCApproved ? Position.RIGHT : undefined}
        iconColor="white"
      >
        {isProcessingIFC ? (
          <Spinner size="small" />
        ) : isIFCApproved ? (
          t('package-creation.ceiling-grid.select-floors')
        ) : (
          t('package-creation.ceiling-grid.check-ifc', { documentsCount: localFiles.length || '' })
        )}
      </Button>
      {isIFCApproved && (
        <div className="mt-2 text-green-600">{t('package-creation.ceiling-grid.ifc-approved')}</div>
      )}
    </>
  );
};
