import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MultiOpenAccordion } from '@/components/accordion/accordion-multi-open';
import { AccordionWrapper } from '@/components/accordion/accordion-wrapper';
import { useFileTreeContext } from '@/contexts/use-file-tree-context';
import { TreeEntry } from '@/pages/folders/util/document-list-to-tree';

import { AllDocuments } from './all-documents';
import { Category } from './category';
import { CategoryContent } from './category-content';

interface AccordionItemProps {
  entry: TreeEntry;
  index: number;
  onClick: (id: string) => void;
}

export const AccordionItem = ({ entry, index, onClick }: AccordionItemProps) => {
  const { t } = useTranslation();

  const header = useCallback(() => {
    return <Category title={entry.toDisplayString(t)} entry={entry} />;
  }, [entry, t]);

  return (
    <MultiOpenAccordion index={index} classNames="[&&]:px-0 [&&]:py-0" disabled header={header}>
      <CategoryContent onClick={onClick} content={entry}></CategoryContent>
    </MultiOpenAccordion>
  );
};

interface CategorySidebarProps {
  categories: [string, TreeEntry][];
}
export const CategoryAccordion = ({ categories }: CategorySidebarProps) => {
  const { setActiveFolderId } = useFileTreeContext();

  const { setPage } = useFileTreeContext();
  const handleCategoryClick = useCallback(
    (id: string) => {
      setActiveFolderId(id);
      setPage(1);
    },
    [setActiveFolderId, setPage],
  );

  return (
    <AccordionWrapper>
      <AllDocuments />
      {categories.map(([, entry], index) => (
        <AccordionItem
          key={entry.id}
          index={index}
          entry={entry}
          onClick={handleCategoryClick}
        ></AccordionItem>
      ))}
    </AccordionWrapper>
  );
};
