import { useMyUser, useRemoveProjectAccessMutation } from '@consigli/hooks';
import { ProjectRole } from '@consigli/types';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { IoClose } from 'react-icons/io5';

interface UserManagementProjectAccessCardProps {
  userId: string;
  userName: string;
  projectId?: number;
  projectName?: string;
  name: string;
  role: ProjectRole;
}

export const UserManagementProjectAccessCard: FC<UserManagementProjectAccessCardProps> = ({
  userId,
  userName,
  projectId,
  projectName,
  name,
  role,
}) => {
  const { user } = useMyUser();
  const { t } = useTranslation();
  const [removeProjectAccess] = useRemoveProjectAccessMutation();
  const isProjectAdmin = role === ProjectRole.ADMIN;
  const canRemoveProject = (user?.isSuperuser || user?.isOrgAdmin) && projectId;

  const handleRemoveAccess = () => {
    if (projectId) {
      const confirmationText = t('user-management.confirm-remove-access', {
        project: projectName,
        user: userName,
      });
      const confirmed = window.confirm(confirmationText);
      if (confirmed) {
        removeProjectAccess({ userId, projectId });
      }
    }
  };

  return (
    <div
      className={clsx(
        isProjectAdmin ? 'bg-night-light-3' : 'bg-day-light-2',
        isProjectAdmin ? 'text-night-neutral-dark' : 'text-day-neutral-dark',
        'px-2 py-1 m-1 text-xs rounded font-bold flex max-w-[8rem]',
      )}
    >
      {isProjectAdmin && (
        <IoShieldCheckmarkOutline size={16} title="ProjectAdminIcon" className="mr-1 shrink-0" />
      )}
      <p className="max-w-48 truncate">{name}</p>
      {canRemoveProject && (
        <IoClose
          size={16}
          title="RemoveAccessIcon"
          className="ml-1 cursor-pointer shrink-0"
          onClick={handleRemoveAccess}
        />
      )}
    </div>
  );
};
