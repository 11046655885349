import {
  LayoutType,
  ServiceId,
  ServiceNameType,
  ServiceName,
  ServicePermissionName,
} from '@consigli/types';

import CeilingPlanIcon from '@/assets/images/services/ceiling-plan-icon.png';
import DueDiligenceIcon from '@/assets/images/services/due-diligence-icon.png';
import FloorPlanIcon from '@/assets/images/services/floor-plan-icon.png';
import OfferIcon from '@/assets/images/services/offer-icon.png';
import OperationsManagementIcon from '@/assets/images/services/operations-management-icon.png';
import ParkingIcon from '@/assets/images/services/parking-icon.png';
import PlantRoomIcon from '@/assets/images/services/plant-room-icon.png';
import ReportsIcon from '@/assets/images/services/reports-icon.png';
import SpaceIcon from '@/assets/images/services/space-analysis-icon.png';
// import StructuralIcon from '@/assets/images/services/structural-icon.png';
import TenantRequirementIcon from '@/assets/images/services/tenant-requirement-icon.png';
import TenderDocumentsIcon from '@/assets/images/services/tender-documents-icon.png';
import { Route } from '@/routes';

export const serviceConfig = [
  {
    serviceName: ServiceNameType.DUE_DILIGENCE,
    servicePermissionName: ServicePermissionName.USE_DUE_DILIGENCE_SERVICE,
    serviceType: ServiceName.DUE_DILIGENCE,
    icon: DueDiligenceIcon,
    tooltipKey: 'service-tooltips.due-diligence',
    titleKey: 'services.due-diligence',
    newServiceRoute: `${Route.DUE_DILIGENCE}/${Route.CREATE}`,
    serviceRoute: Route.DUE_DILIGENCE,
    serviceId: ServiceId.DUE_DILIGENCE,
  },
  {
    serviceName: ServiceNameType.TENDER_DOCUMENTS,
    servicePermissionName: ServicePermissionName.USE_TENDER_DOCUMENTS_SERVICE,
    serviceType: ServiceName.TENDER_DOCUMENTS,
    icon: TenderDocumentsIcon,
    tooltipKey: 'service-tooltips.tender-documents',
    titleKey: 'services.tender-documents',
    newServiceRoute: `${Route.TENDER_DOCUMENTS}/${Route.CREATE}`,
    serviceRoute: Route.TENDER_DOCUMENTS,
    serviceId: ServiceId.TENDER_DOCUMENTS,
  },
  {
    serviceName: ServiceNameType.OFFER,
    servicePermissionName: ServicePermissionName.USE_OFFER_SERVICE,
    serviceType: ServiceName.OFFER,
    icon: OfferIcon,
    tooltipKey: 'service-tooltips.offer',
    titleKey: 'services.offer',
    newServiceRoute: `${Route.OFFER}/${Route.CREATE}`,
    serviceRoute: Route.OFFER,
    serviceId: ServiceId.OFFER,
  },
  {
    serviceName: ServiceNameType.TENANT_REQUIREMENTS,
    servicePermissionName: ServicePermissionName.USE_TENANT_REQ_SERVICE,
    serviceType: ServiceName.TENANT_REQUIREMENTS,
    icon: TenantRequirementIcon,
    tooltipKey: 'service-tooltips.tenant-requirements',
    titleKey: 'services.tenant-requirements',
    newServiceRoute: `${Route.TENANT_REQUIREMENTS}/${Route.CREATE}`,
    serviceRoute: Route.TENANT_REQUIREMENTS,
    serviceId: ServiceId.TENANT_REQUIREMENTS,
  },
  {
    serviceName: ServiceNameType.MANAGEMENT_OPERATIONS_MAINTENANCE,
    servicePermissionName: ServicePermissionName.USE_MANAGE_OPERATE_SERVICE,
    serviceType: ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE,
    icon: OperationsManagementIcon,
    tooltipKey: 'service-tooltips.management-operations-maintenance',
    titleKey: 'services.management-operations-maintenance',
    newServiceRoute: `${Route.MANAGEMENT_OPERATIONS_MAINTENANCE}/${Route.CREATE}`,
    serviceRoute: Route.MANAGEMENT_OPERATIONS_MAINTENANCE,
    serviceId: ServiceId.MANAGEMENT_OPERATIONS_MAINTENANCE,
  },
  {
    serviceName: ServiceNameType.CEILING_PLAN,
    servicePermissionName: ServicePermissionName.USE_CEILING_PLAN_SERVICE,
    serviceType: ServiceName.CEILING_PLAN,
    icon: CeilingPlanIcon,
    tooltipKey: 'service-tooltips.ceiling-plan',
    titleKey: 'services.ceiling-plan',
    newServiceRoute: `${Route.CEILING_PLAN}/${Route.CREATE}`,
    serviceRoute: Route.CEILING_PLAN,
    serviceId: LayoutType.CEILING_PLAN,
  },
  {
    serviceName: ServiceNameType.PLANT_ROOM,
    servicePermissionName: ServicePermissionName.USE_PLANT_ROOM_SERVICE,
    serviceType: ServiceName.PLANT_ROOM,
    icon: PlantRoomIcon,
    tooltipKey: 'service-tooltips.plant-room',
    titleKey: 'services.plant-room',
    newServiceRoute: `${Route.PLANT_ROOM}/${Route.CREATE}`,
    serviceRoute: Route.PLANT_ROOM,
    serviceId: LayoutType.PLANT_ROOM,
  },
  {
    serviceName: ServiceNameType.FLOOR_PLAN,
    servicePermissionName: ServicePermissionName.USE_FLOOR_PLAN_SERVICE,
    serviceType: ServiceName.FLOOR_PLAN,
    icon: FloorPlanIcon,
    tooltipKey: 'service-tooltips.floor-plan',
    titleKey: 'services.floor-plan',
    newServiceRoute: `${Route.FLOOR_PLAN}/${Route.CREATE}`,
    serviceRoute: Route.FLOOR_PLAN,
    serviceId: LayoutType.FLOOR_PLAN,
  },
  {
    serviceName: ServiceNameType.SPACE_ANALYSIS,
    servicePermissionName: ServicePermissionName.USE_SPACE_ANALYSIS_SERVICE,
    serviceType: ServiceName.SPACE_ANALYSIS,
    icon: SpaceIcon,
    tooltipKey: 'service-tooltips.space-analysis',
    titleKey: 'services.space-analysis',
    newServiceRoute: `${Route.SPACE_ANALYSIS}/${Route.CREATE}`,
    serviceRoute: Route.SPACE_ANALYSIS,
    serviceId: LayoutType.SPACE_ANALYSIS,
  },
  {
    serviceName: ServiceNameType.PARKING,
    servicePermissionName: ServicePermissionName.USE_PARKING_SERVICE,
    serviceType: ServiceName.PARKING,
    icon: ParkingIcon,
    tooltipKey: 'service-tooltips.parking',
    titleKey: 'services.parking',
    newServiceRoute: `${Route.PARKING}/${Route.CREATE}`,
    serviceRoute: Route.PARKING,
    serviceId: LayoutType.PARKING,
  },
  {
    serviceName: ServiceNameType.REPORTS,
    servicePermissionName: ServicePermissionName.USE_REPORTS_SERVICE,
    serviceType: ServiceName.REPORTS,
    icon: ReportsIcon,
    tooltipKey: 'service-tooltips.reports',
    titleKey: 'services.reports',
    newServiceRoute: `${Route.REPORTS}/${Route.CREATE}`,
    serviceRoute: Route.REPORTS,
    serviceId: LayoutType.REPORTS,
  },
  // Add more services as needed
];

export const superUserDeveloperServicesOrder = [
  'DUE_DILIGENCE',
  'SPACE_ANALYSIS',
  'FLOOR_PLAN',
  'PARKING',
  'CEILING_PLAN',
  'REPORTS',
  'PLANT_ROOM',
  'TENDER_DOCUMENTS',
  'OFFER',
  'TENANT_REQUIREMENTS',
  'MANAGEMENT_OPERATIONS_MAINTENANCE',
  'CEILING_GRID',
];

export const developerServicesOrder = [
  'DUE_DILIGENCE',
  'SPACE_ANALYSIS',
  'FLOOR_PLAN',
  'PARKING',
  'CEILING_PLAN',
  'REPORTS',
  'PLANT_ROOM',
  'TENDER_DOCUMENTS',
  'OFFER',
  'TENANT_REQUIREMENTS',
  'MANAGEMENT_OPERATIONS_MAINTENANCE',
];

export const superUserContractorServicesOrder = [
  'TENDER_DOCUMENTS',
  'OFFER',
  'SPACE_ANALYSIS',
  'FLOOR_PLAN',
  'PARKING',
  'CEILING_PLAN',
  'REPORTS',
  'PLANT_ROOM',
  'TENANT_REQUIREMENTS',
  'MANAGEMENT_OPERATIONS_MAINTENANCE',
  'CEILING_GRID',
];

export const contractorServicesOrder = [
  'TENDER_DOCUMENTS',
  'OFFER',
  'SPACE_ANALYSIS',
  'FLOOR_PLAN',
  'PARKING',
  'CEILING_PLAN',
  'REPORTS',
  'PLANT_ROOM',
  'TENANT_REQUIREMENTS',
  'MANAGEMENT_OPERATIONS_MAINTENANCE',
];
