import { LoadingArea, SelectOption } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useBlobsNSCount,
  useQuery,
  useDebouncedSearch,
} from '@consigli/hooks';
import { Blob, FoldersDocumentType } from '@consigli/types';
import { useMemo, type FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FileTreeContext } from '@/contexts/use-file-tree-context';
import { WorkspaceProvider } from '@/contexts/use-workspace-context';
import { Breadcrumb } from '@/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/layouts/workspace-layout/workspace-layout';
import { FoldersContainer } from '@/pages/folders/folders-container';
import { createTree } from '@/pages/folders/util/document-list-to-tree';

import { status } from './content/draggable-container';

export const FoldersPage: FC = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const queryParams = useQuery();
  const querySearchTerm = queryParams.get('q');
  const [activeFolderId, setActiveFolderId] = useState('');
  const [activeFolderFiles, setActiveFolderFiles] = useState<Blob[]>([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<SelectOption[]>([]);
  const [selectedBlobIds, setSelectedBlobIds] = useState<SelectOption[]>([]);
  const [page, setPage] = useState(1);
  const [searchContent, setSearchContent] = useState(querySearchTerm || '');
  const [searchFileName, setSearchFileName] = useState('');
  const [tree, setTree] = useState(useMemo(() => createTree(), []));
  const [selectedCard, setSelectedCard] = useState('');
  const [labelsReused, setLabelsReused] = useState(false);
  const debouncedSearchContent = useDebouncedSearch(searchContent, 500);
  const debouncedSearchFileName = useDebouncedSearch(searchFileName, 500);
  const { data, isLoading } = useBlobsNSCount({
    packageId,
    projectId,
    documentType: selectedDocumentTypes.map((option) => option.value as FoldersDocumentType),
    searchParsedData: debouncedSearchContent.length >= 2 ? debouncedSearchContent : '',
    blobIds: selectedBlobIds.map((option) => option.value as string),
    searchName: debouncedSearchFileName,
    status,
    labelsReused: labelsReused,
  });
  const [summaryCount, setSummaryCount] = useState(data);
  useEffect(() => {
    setSummaryCount(data);
  }, [data]);
  return (
    <WorkspaceProvider>
      <WorkspaceLayout>
        <Breadcrumb label={t('servicetabs.folders')} />
        {isLoading ? (
          <LoadingArea title={t('folders.loading-folders')} />
        ) : (
          <FileTreeContext.Provider
            value={{
              activeFolderFiles,
              setActiveFolderFiles,
              activeFolderId,
              setActiveFolderId,
              tree,
              setTree,
              summaryCount,
              page,
              setPage,
              setSelectedDocumentTypes,
              selectedDocumentTypes,
              selectedBlobIds,
              setSelectedBlobIds,
              searchContent,
              setSearchContent,
              selectedCard,
              setSelectedCard,
              searchFileName,
              setSearchFileName,
              labelsReused,
              setLabelsReused,
            }}
          >
            <FoldersContainer />
          </FileTreeContext.Provider>
        )}
      </WorkspaceLayout>
    </WorkspaceProvider>
  );
};
