import { LoadingArea } from '@consigli/facade';
import { useGetMyUserQuery } from '@consigli/hooks';
import { logout } from '@consigli/oauth';
import { type FC, type PropsWithChildren, useEffect, useState, useRef } from 'react';

import { authConfig } from '@/util/oauth/config';

/** Auth will be the callback from OAuth. So where we'll retrieve the token and store it */
export const UserGuard: FC<PropsWithChildren> = ({ children }) => {
  const { data: user, isLoading } = useGetMyUserQuery();
  const [loadingMessage, setLoadingMessage] = useState('Checking authorization');
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleLogout = () => {
    setLoadingMessage(
      'SSO disabled for your organization. Please login with your username and password',
    );
    setTimeout(() => {
      logout(authConfig);
    }, 5000);
  };

  useEffect(() => {
    // Start 15s timer when query begins
    timeoutRef.current = setTimeout(() => {
      if (!user) {
        handleLogout();
      }
    }, 30000);

    // Cleanup timeout when component unmounts or when user data arrives
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [user]);

  // I cant use the error effect as the API lib is retrying and eating up 401 error
  // TODO: may be experiment with not eating up 401 error on the api lib
  useEffect(() => {
    if (!isLoading && !user) {
      handleLogout();
    }
  }, [isLoading, user]);

  return user ? (
    <>{children}</>
  ) : (
    <LoadingArea title={loadingMessage} className="mt-[40vh] mx-auto" />
  );
};
