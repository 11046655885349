import { useKeyListener } from '@consigli/hooks';
import { FindingDocument } from '@consigli/types';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';

import { FindingsCommentSection } from './findings-comment-section';

export const FindingsCommentSectionWrapper = () => {
  const { t } = useTranslation();
  const { document, mode } = useViewerContext();
  const { close } = useViewer();
  const [isOpened, setIsOpened] = useState(false);
  const delayedCloseFlag = useRef(false);

  useEffect(() => {
    if (mode === ViewerMode.CommentPreview && !delayedCloseFlag.current) {
      setTimeout(() => {
        setIsOpened(true);
      }, 70);
    }
    return () => {
      delayedCloseFlag.current = false;
    };
  }, [mode]);

  const delayedClose = () => {
    setIsOpened(false);
    delayedCloseFlag.current = true;
    setTimeout(() => {
      close();
    }, 700);
  };

  useKeyListener('Escape', delayedClose);

  return (
    <div
      className={clsx(
        'shadow fixed z-[50] border-l border-[#ebebeb] top-14 right-[-35vw] w-[35vw] transition-all duration-700 ease-in-out h-full',
        isOpened && 'translate-x-[-35vw]',
      )}
    >
      <div className="flex flex-col bg-white h-full">
        <div className="flex flex-row text-brand-default">
          <b className="ml-4 mt-2 text-xl">Comments</b>
          <div className="ml-auto">
            <button
              onClick={delayedClose}
              className="fa-solid fa-x fa-lg h-10 w-10 pr-4"
              title={t('findings.buttons.close')}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center overflow-y-auto">
          {document && <FindingsCommentSection finding={document as FindingDocument} />}
        </div>
      </div>
    </div>
  );
};
