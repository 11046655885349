import { LoadingArea } from '@consigli/facade';
import {
  Components,
  SimpleScene,
  SimpleCamera,
  SimpleRenderer,
  SimpleWorld,
  Worlds,
  IfcLoader,
} from '@thatopen/components';
import { useEffect, useRef, FC, useState } from 'react';
import { Color } from 'three';

type IfcViewerProps = {
  fileUrl: string;
};

export const IfcViewer: FC<IfcViewerProps> = ({ fileUrl }) => {
  const viewerContainerRef = useRef<HTMLDivElement>(null);
  const [components, setComponents] = useState<Components>();
  const [world, setWorld] = useState<SimpleWorld>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (viewerContainerRef.current) {
      const components = new Components();

      const worlds = components.get(Worlds);

      const world = worlds.create<SimpleScene, SimpleCamera, SimpleRenderer>();
      world.scene = new SimpleScene(components);
      // Use the ref's current value instead of getElementById
      world.renderer = new SimpleRenderer(components, viewerContainerRef.current);
      world.camera = new SimpleCamera(components);

      components.init();

      world.scene.setup();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      world.scene.three.background = new Color('#ffffff') as any;

      setComponents(components);
      setWorld(world);

      return () => {
        components.dispose();
      };
    }
  }, []);

  useEffect(() => {
    async function loadIfcAsFragments(components: Components, fileUrl: string) {
      const fragmentIfcLoader = components.get(IfcLoader);

      await fragmentIfcLoader.setup();
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;

      const file = await fetch(fileUrl);
      const data = await file.arrayBuffer();
      const buffer = new Uint8Array(data);
      const model = await fragmentIfcLoader.load(buffer);
      model.name = 'IFC Model';
      if (world) {
        world.scene.three.add(model);
      }

      setIsLoading(false);
    }
    if (components) {
      loadIfcAsFragments(components, fileUrl);
    }
  }, [fileUrl, components, world]);

  return (
    <div className="w-full h-full pt-2">
      {isLoading && (
        <div className="absolute inset-0 bg-white/70 z-10 flex justify-center items-center">
          <LoadingArea />
        </div>
      )}
      <div className="w-full h-full" ref={viewerContainerRef}></div>
    </div>
  );
};
