import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

type WorkspaceContext = {
  isOverlayVisible: boolean;
  setIsOverlayVisible: (value: boolean) => void;
};

export const WorkspaceContext = createContext<WorkspaceContext | null>(null);

export const useWorkspaceContext = () => {
  const ctx = useContext(WorkspaceContext);

  if (!ctx) {
    throw new Error('Missing WorkspaceProvider in tree');
  }

  return ctx;
};

export const WorkspaceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

  const value = {
    isOverlayVisible,
    setIsOverlayVisible,
  };

  return <WorkspaceContext.Provider value={value}>{children}</WorkspaceContext.Provider>;
};
