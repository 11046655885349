import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { processorJobSchema } from './processor-job';
export var ProcessorJobStageStatus;
(function (ProcessorJobStageStatus) {
    ProcessorJobStageStatus["INITIALIZED"] = "INITIALIZED";
    ProcessorJobStageStatus["STARTED"] = "STARTED";
    ProcessorJobStageStatus["FAILED"] = "FAILED";
    ProcessorJobStageStatus["COMPLETED"] = "COMPLETED";
})(ProcessorJobStageStatus || (ProcessorJobStageStatus = {}));
export const processorJobStageStatusSchema = z.nativeEnum(ProcessorJobStageStatus);
export const processorJobStageSchema = z
    .object({
    job_id: processorJobSchema.innerType().shape.id,
    stage_name: z.string(),
    status: processorJobStageStatusSchema,
    status_description: z.string().nullable(),
    created_at: z.string(),
    updated_at: z.string(),
    total_tasks: z.number(),
    total_failed_tasks: z.number(),
    completed_tasks: z.number(),
    is_all_tasks_complete: z.boolean(),
})
    .strict()
    .transform(mapToCamelCase);
export const getProcessorJobStagesSchema = z
    .array(processorJobStageSchema)
    .transform(mapToCamelCase);
export const getProcessorJobStageSchema = processorJobStageSchema;
